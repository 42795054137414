<template>
    <div class="designer">
        <b-tabs :fill="screenWidth <= 700" pills card content-class="mt-3" v-model="activeStep" class="designer-tabs" header-class="designer-header" @activate-tab="showContent()" >
            
            <b-tab  >
                <template v-slot:title>
                        1 <span class="d-none d-sm-inline" v-bind:class="{'d-inline': activeStep==0}">Personalize</span> <b-icon icon="check2-circle" variant="success" small v-if="step1Complete"></b-icon>
                </template>
                <Personalization @updateStep1="setStep1"/>
            </b-tab>
            <b-tab :title-link-class="{'disabled':step2Disabled}">
                <template v-slot:title>
                        2 <span class="d-none d-sm-inline" v-bind:class="{'d-inline': activeStep==1}">Front of Page</span> <b-icon icon="check2-circle" variant="success" small v-if="step2Complete"></b-icon>
                </template>
                <Step2  @updateStep2="setStep2" ref="Step2"/>
            </b-tab>
            <b-tab  :title-link-class="{'disabled':step3Disabled}">
                <template v-slot:title>
                        3 <span class="d-none d-sm-inline" v-bind:class="{'d-inline': activeStep==2}">Back of Page</span> <b-icon icon="check2-circle" variant="success" small v-if="step3Complete" ></b-icon>
                </template>
                <Step3 @updateStep3="setStep3" ref="Step3"  :journalProperties="journalProperties"/>
            </b-tab>
            <b-tab :title-link-class="{'disabled':step4Disabled}" >
                <template v-slot:title>
                        4 <span class="d-none d-sm-inline" v-bind:class="{'d-inline': activeStep==3}">Preview</span> 
                </template>
                <Step4 @updateStep4="setStep4" ref="Step4" :journalProperties="journalProperties"/>
            </b-tab>
        </b-tabs>
  

    </div>
    
</template>

<style >
     .nav-link{
        font-size: 13px !important;
    }
</style>

<script>
import Personalization from './Personalization'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'

export default {
    name: 'Designer',
    data: function(){
        return{
            step1Complete: false,
            step2Complete: false,
            step3Complete: false,
            step4Complete: false,

            step2Disabled: true,
            step3Disabled : true,
            step4Disabled:true,
            activeStep:0,
            journalProperties:{
                journalOwner:"",
                frontPage:{},
                backPage:{}
            },
            screenWidth:1200
        }
    },
    components: {
        Personalization,
        Step2: Step2,
        Step3: Step3,
        Step4: Step4
    },
    mounted(){
        window.addEventListener("resize", ()=> this.screenWidth = screen.width);
    },
    methods: {
        setStep1(v) {
            this.step1Complete = true;
            this.journalProperties.journalOwner = v;
            this.step2Disabled = false;
            this.activeStep = 1;
            var parsedObj = JSON.parse(JSON.stringify(this.journalProperties))
            console.log(parsedObj)
        },
        setStep2(v) {
            this.journalProperties.frontPage = v;
            this.step2Complete = true;
            this.activeStep =2;
            this.step3Disabled = false;
            var parsedObj = JSON.parse(JSON.stringify(this.journalProperties))
            console.log(parsedObj)
        },
        setStep3(v) {
           this.journalProperties.backPage = v;
            this.step3Complete = true;
            this.activeStep =3;
            this.step4Disabled = false;
            var parsedObj = JSON.parse(JSON.stringify(this.journalProperties))
            console.log(parsedObj)
            
        },
        setStep4(v) {
           this.journalProperties.backPage = v;
            this.step3Complete = true;
            this.activeStep =3;
            this.step4Disabled = false;
            var parsedObj = JSON.parse(JSON.stringify(this.journalProperties))
            console.log(parsedObj)
            
        },
        showContent:function(){

           this.$refs.Step2.scaleFrontPage();
            this.$refs.Step2.activeSection = null;

           this.$refs.Step3.scaleBackPage();
           this.$refs.Step3.activeSection = null;

           this.$refs.Step4.scaleReviewPage();
           this.$refs.Step4.activeSection = null;
           this.$refs.Step4.resetPages();
        }
    }
}


</script>