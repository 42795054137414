<template>

    <div class="text-center">
        <div style="width:100%; max-width:450px; margin:0px auto; padding:20px; background-color: #83c9dc;">
            <div style="font-weight:bold;">
                Enter the Name of the Lucky Journal Owner
            </div>
            <div><small>(optional)</small></div>
             <div class="mt-4">
                <b-form-input v-model="JournalOwner" placeholder="Enter name"></b-form-input>
                
            </div>
            <div class="mt-4 mb-4">
                <button class="btn btn-sm btn-secondary" v-on:click="ValidateStep1()">Continue <b-icon icon="arrow-right"></b-icon></button>
            </div>
            <b-alert variant="danger" :show="showError" @dismissed="dismissed()" dismissible fade>{{errorMsg}}</b-alert>
        </div>
    </div>
</template>



<script>
export default {
    data: function () {
        return {
            counter: 0,
            errorMsg:null,
            showError:0,
            JournalOwner:null
        }
    },
    methods:{
        ValidateStep1: function(){
            this.$emit('updateStep1', this.JournalOwner)
        },
        dismissed: function(){
           // console.log('dismissed');
            this.showError = 0;
        }
    }
}
</script>
