import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import Fitty from 'vue-fitty' 


import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import UUID from 'vue-uuid'
import vueScrollto from 'vue-scrollto'

Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueLodash, { lodash: lodash })
Vue.use(Fitty)
Vue.use(UUID); 
Vue.use(vueScrollto)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  data:{
    step1Complete: Boolean 
  }
}).$mount('#app')
