<template>
    <div>
        <div>
            <!-- select prompts -->
            <div >
                <h3>Review Your Journal</h3>
                <div>
                    <div class="row">
                        <div class="col">
                            <div id="review-wrapper" >
                                <div id="review-page" v-bind:style="{ backgroundImage: 'url(' + getCoverImg() + ')' }"   v-bind:class="{'metal' : binding=='metal', 'spiral': binding=='spiral',  'binding-left': currentPage == 1 || currentPage==2, 'binding-right':currentPage==3}" style="width: 550px; height: 850px; transform:scale(0.30) translateX(-50%); background-size:cover; background-repeat:no-repeat;" >
                                    <div style="display:flex; flex-direction:column; flex:1;" v-bind:class="{'pad-left': currentPage==1 || currentPage ==2, 'pad-right': currentPage==3}">
                                        <!-- layout-1 -->
                                        <div v-if="activeLayout=='layout-1'" style="display:flex; flex-direction:column; flex:1" >
                                            <!-- section 1 -->
                                            <div style="display:flex; flex:1; flex-direction:column; "  v-bind:class="{'active' : activeSection==1, 'edit-section-wrapper': reviewPage.section1.isSimple==false, 'edit-section-wrapper-no-border': reviewPage.section1.isSimple==true}" >
                                            <div style="text-align:center; height:50px; margin-top:10px; width:450px; margin-left:auto; margin-right:auto;">
                                            <fitty  :options="fittyOptions">
                                                <template v-slot:content>
                                                    <span   v-bind:style="{'font-family':reviewPage.section1.font}" v-bind:class="{'bold': reviewPage.section1.bold, 'italic': reviewPage.section1.italic, 'underline': reviewPage.section1.underline}">{{reviewPage.section1.prompt}}</span>
                                                </template>
                                            </fitty>
                                            </div>
                                            <div style="display:flex; flex:1; margin-bottom:25px" v-bind:class="{'bullets-bg': reviewPage.section1.bullets==true}">
                                                <div class="background" v-bind:class="{'grid-bg': reviewPage.simpleBackground == 'grid' && reviewPage.section1.isSimple, 'dotted-bg': reviewPage.simpleBackground == 'dotted' && reviewPage.section1.isSimple, 'lined-bg': reviewPage.simpleBackground == 'lined' && reviewPage.section1.isSimple || reviewPage.section1.background == 'lined-bg'}" style="display:flex; flex:1">
                                                </div>
                                            </div>
                                            </div>
                                        </div>

                                        <!-- layout-1:1 -->
                                        <div v-if="activeLayout=='layout-1:1'" style="display:flex; flex-direction:column; flex:1">
                                            <!-- section 1 -->
                                            <div style="display:flex; flex:1; flex-direction:column; " class="edit-section-wrapper" >
                                                <div style="text-align:center; height:50px; margin-top:10px; width:450px; margin-left:auto; margin-right:auto;">
                                                    <fitty  :options="fittyOptions">
                                                        <template v-slot:content>
                                                            <span   v-bind:style="{'font-family':reviewPage.section1.font}" v-bind:class="{'bold': reviewPage.section1.bold, 'italic': reviewPage.section1.italic, 'underline': reviewPage.section1.underline}">{{reviewPage.section1.prompt}}</span>
                                                        </template>
                                                    </fitty>
                                                </div>
                                                <div style="display:flex; flex:1; margin-bottom:25px; " v-bind:class="{'bullets-bg': reviewPage.section1.bullets==true}">
                                                    <div class="background lined-bg" style="display:flex; flex:1">
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            
                                            <!-- section 2 -->
                                            <div style="display:flex; flex:1; flex-direction:column; border-top:none;" class="edit-section-wrapper" >
                                                <div style="text-align:center; height:50px; margin-top:10px; width:450px; margin-left:auto; margin-right:auto;">
                                                    <fitty  :options="fittyOptions">
                                                        <template v-slot:content>
                                                            <span   v-bind:style="{'font-family':reviewPage.section2.font}" v-bind:class="{'bold': reviewPage.section2.bold, 'italic': reviewPage.section2.italic, 'underline': reviewPage.section2.underline}">{{reviewPage.section2.prompt}}</span>
                                                        </template>
                                                    </fitty>
                                                </div>
                                                <div style="display:flex; flex:1; margin-bottom:25px;" v-bind:class="{'bullets-bg': reviewPage.section2.bullets==true}">
                                                    <div class="background lined-bg"  style="display:flex; flex:1">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- end layout-1:1 -->


                                        <!-- layout-1:2 -->
                                        <div v-if="activeLayout=='layout-1:2'" style="display:flex; flex-direction:column; flex:1">
                                            <!-- section 1 -->
                                            <div style="display:flex; flex:1; flex-direction:column; " class="edit-section-wrapper" >
                                                <div style="text-align:center; height:50px; margin-top:10px; width:450px; margin-left:auto; margin-right:auto;">
                                                    <fitty  :options="fittyOptions">
                                                        <template v-slot:content>
                                                            <span   v-bind:style="{'font-family':reviewPage.section1.font}" v-bind:class="{'bold': reviewPage.section1.bold, 'italic': reviewPage.section1.italic, 'underline': reviewPage.section1.underline}">{{reviewPage.section1.prompt}}</span>
                                                        </template>
                                                    </fitty>
                                                </div>
                                                <div style="display:flex; flex:1; margin-bottom:25px; " v-bind:class="{'bullets-bg': reviewPage.section1.bullets==true}">
                                                    <div class="background lined-bg" style="display:flex; flex:1">
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            
                                            <div style="display:flex; flex:1;">
                                                <!-- section 2 -->
                                                <div style="display:flex; flex:1; flex-direction:column; border-top:none; border-right:none;" class="edit-section-wrapper" >
                                                    <div style="text-align:center; height:50px; margin-top:10px; width:225px; margin-left:auto; margin-right:auto;">
                                                    <fitty  :options="fittyOptionsSm">
                                                        <template v-slot:content>
                                                            <span   v-bind:style="{'font-family':reviewPage.section2.font}" v-bind:class="{'bold': reviewPage.section2.bold, 'italic': reviewPage.section2.italic, 'underline': reviewPage.section2.underline}">{{reviewPage.section2.prompt}}</span>
                                                        </template>
                                                    </fitty>
                                                    </div>
                                                    <div style="display:flex; flex:1; margin-bottom:25px;" v-bind:class="{'bullets-bg': reviewPage.section2.bullets==true}">
                                                        <div class="background lined-bg"  style="display:flex; flex:1">
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- section 3 -->
                                                <div style="display:flex; flex:1; flex-direction:column; border-top:none;" class="edit-section-wrapper" >
                                                    <div style="text-align:center; height:50px; margin-top:10px; width:225px; margin-left:auto; margin-right:auto;">
                                                        <fitty  :options="fittyOptionsSm">
                                                            <template v-slot:content>
                                                                <span   v-bind:style="{'font-family':reviewPage.section3.font}" v-bind:class="{'bold': reviewPage.section3.bold, 'italic': reviewPage.section3.italic, 'underline': reviewPage.section3.underline}">{{reviewPage.section3.prompt}}</span>
                                                            </template>
                                                        </fitty>
                                                    </div>
                                                    <div style="display:flex; flex:1; margin-bottom:25px;" v-bind:class="{'bullets-bg': reviewPage.section3.bullets==true}">
                                                        <div class="background lined-bg"  style="display:flex; flex:1">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- end layout-1:2 -->

                                        <!-- layout-2:1 -->
                                        <div v-if="activeLayout=='layout-2:1'" style="display:flex; flex-direction:column; flex:1">
                                            <div style="display:flex; flex:1;">
                                                <!-- section 1 -->
                                                <div style="display:flex; flex:1; flex-direction:column; border-right:none; " class="edit-section-wrapper" >
                                                    <div style="text-align:center; height:50px; margin-top:10px; width:225px; margin-left:auto; margin-right:auto;">
                                                        <fitty  :options="fittyOptionsSm">
                                                            <template v-slot:content>
                                                                <span   v-bind:style="{'font-family':reviewPage.section1.font}" v-bind:class="{'bold': reviewPage.section1.bold, 'italic': reviewPage.section1.italic, 'underline': reviewPage.section1.underline}">{{reviewPage.section1.prompt}}</span>
                                                            </template>
                                                        </fitty>
                                                    </div>

                                                    <div style="display:flex; flex:1; margin-bottom:25px; " v-bind:class="{'bullets-bg': reviewPage.section1.bullets==true}">
                                                        <div class="background lined-bg" style="display:flex; flex:1">
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                
                                            
                                                <!-- section 2 -->
                                                <div style="display:flex; flex:1; flex-direction:column;" class="edit-section-wrapper" >
                                                    <div style="text-align:center; height:50px; margin-top:10px; width:225px; margin-left:auto; margin-right:auto;">
                                                        <fitty  :options="fittyOptionsSm">
                                                            <template v-slot:content>
                                                                <span   v-bind:style="{'font-family':reviewPage.section2.font}" v-bind:class="{'bold': reviewPage.section2.bold, 'italic': reviewPage.section2.italic, 'underline': reviewPage.section2.underline}">{{reviewPage.section2.prompt}}</span>
                                                            </template>
                                                        </fitty>
                                                    </div>
                                                    <div style="display:flex; flex:1; margin-bottom:25px;" v-bind:class="{'bullets-bg': reviewPage.section2.bullets==true}">
                                                        <div class="background lined-bg"  style="display:flex; flex:1">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- section 3 -->
                                            <div style="display:flex; flex:1; flex-direction:column; border-top:none;" class="edit-section-wrapper" >
                                                <div style="text-align:center; height:50px; margin-top:10px; width:450px; margin-left:auto; margin-right:auto;">
                                                    <fitty  :options="fittyOptions">
                                                        <template v-slot:content>
                                                            <span   v-bind:style="{'font-family':reviewPage.section3.font}" v-bind:class="{'bold': reviewPage.section3.bold, 'italic': reviewPage.section3.italic, 'underline': reviewPage.section3.underline}">{{reviewPage.section3.prompt}}</span>
                                                        </template>
                                                    </fitty>
                                                </div>
                                                <div style="display:flex; flex:1; margin-bottom:25px;" v-bind:class="{'bullets-bg': reviewPage.section3.bullets==true}">
                                                    <div class="background lined-bg"  style="display:flex; flex:1">
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <!-- end layout-2:1 -->


                                        <!-- layout-2:2 -->
                                        <div v-if="activeLayout=='layout-2:2'" style="display:flex; flex-direction:column; flex:1">
                                            <div style="display:flex; flex:1;">
                                                <!-- section 1 -->
                                                <div style="display:flex; flex:1; flex-direction:column; border-right:none; " class="edit-section-wrapper" >
                                                    <div style="text-align:center; height:50px; margin-top:10px; width:225px; margin-left:auto; margin-right:auto;">
                                                        <fitty  :options="fittyOptionsSm">
                                                            <template v-slot:content>
                                                                <span   v-bind:style="{'font-family':reviewPage.section1.font}" v-bind:class="{'bold': reviewPage.section1.bold, 'italic': reviewPage.section1.italic, 'underline': reviewPage.section1.underline}">{{reviewPage.section1.prompt}}</span>
                                                            </template>
                                                        </fitty>
                                                    </div>
                                                    <div style="display:flex; flex:1; margin-bottom:25px; " v-bind:class="{'bullets-bg': reviewPage.section1.bullets==true}">
                                                        <div class="background lined-bg" style="display:flex; flex:1">
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                
                                            
                                                <!-- section 2 -->
                                                <div style="display:flex; flex:1; flex-direction:column;" class="edit-section-wrapper" >
                                                    <div style="text-align:center; height:50px; margin-top:10px; width:225px; margin-left:auto; margin-right:auto;">
                                                        <fitty  :options="fittyOptionsSm">
                                                            <template v-slot:content>
                                                                <span   v-bind:style="{'font-family':reviewPage.section2.font}" v-bind:class="{'bold': reviewPage.section2.bold, 'italic': reviewPage.section2.italic, 'underline': reviewPage.section2.underline}">{{reviewPage.section2.prompt}}</span>
                                                            </template>
                                                        </fitty>
                                                    </div>
                                                    <div style="display:flex; flex:1; margin-bottom:25px;" v-bind:class="{'bullets-bg': reviewPage.section2.bullets==true}">
                                                        <div class="background lined-bg"  style="display:flex; flex:1">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div style="display:flex; flex:1;">
                                                <!-- section 3 -->
                                                <div style="display:flex; flex:1; flex-direction:column; border-top:none; border-right:none;" class="edit-section-wrapper" >
                                                    <div style="text-align:center; height:50px; margin-top:10px; width:225px; margin-left:auto; margin-right:auto;">
                                                        <fitty  :options="fittyOptionsSm">
                                                            <template v-slot:content>
                                                                <span   v-bind:style="{'font-family':reviewPage.section3.font}" v-bind:class="{'bold': reviewPage.section3.bold, 'italic': reviewPage.section3.italic, 'underline': reviewPage.section3.underline}">{{reviewPage.section3.prompt}}</span>
                                                            </template>
                                                        </fitty>
                                                    </div>
                                                    <div style="display:flex; flex:1; margin-bottom:25px;" v-bind:class="{'bullets-bg': reviewPage.section3.bullets==true}">
                                                        <div class="background lined-bg"  style="display:flex; flex:1">
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- section 4 -->
                                                <div style="display:flex; flex:1; flex-direction:column; border-top:none;" class="edit-section-wrapper" >
                                                    <div style="text-align:center; height:50px; margin-top:10px; width:225px; margin-left:auto; margin-right:auto;">
                                                        <fitty  :options="fittyOptionsSm">
                                                            <template v-slot:content>
                                                                <span   v-bind:style="{'font-family':reviewPage.section4.font}" v-bind:class="{'bold': reviewPage.section4.bold, 'italic': reviewPage.section4.italic, 'underline': reviewPage.section4.underline}">{{reviewPage.section4.prompt}}</span>
                                                            </template>
                                                        </fitty>
                                                    </div>
                                                    <div style="display:flex; flex:1; margin-bottom:25px;" v-bind:class="{'bullets-bg': reviewPage.section4.bullets==true}">
                                                        <div class="background lined-bg"  style="display:flex; flex:1">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- end layout-2:2 -->

                                        <!-- layout-1:1:1 -->
                                        <div v-if="activeLayout=='layout-1:1:1'" style="display:flex; flex-direction:column; flex:1">
                                            <!-- section 1 -->
                                            <div style="display:flex; flex:1; flex-direction:column; " class="edit-section-wrapper" >
                                                <div style="text-align:center; height:50px; margin-top:10px; width:450px; margin-left:auto; margin-right:auto;">
                                                    <fitty  :options="fittyOptions">
                                                        <template v-slot:content>
                                                            <span   v-bind:style="{'font-family':reviewPage.section1.font}" v-bind:class="{'bold': reviewPage.section1.bold, 'italic': reviewPage.section1.italic, 'underline': reviewPage.section1.underline}">{{reviewPage.section1.prompt}}</span>
                                                        </template>
                                                    </fitty>
                                                </div>
                                                <div style="display:flex; flex:1; margin-bottom:5px; " v-bind:class="{'bullets-bg': reviewPage.section1.bullets==true}">
                                                    <div class="background lined-bg" style="display:flex; flex:1">
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            
                                            <!-- section 2 -->
                                            <div style="display:flex; flex:1; flex-direction:column; border-top:none; " class="edit-section-wrapper" >
                                                <div style="text-align:center; height:50px; margin-top:10px; width:450px; margin-left:auto; margin-right:auto;">
                                                    <fitty  :options="fittyOptions">
                                                        <template v-slot:content>
                                                            <span   v-bind:style="{'font-family':reviewPage.section2.font}" v-bind:class="{'bold': reviewPage.section2.bold, 'italic': reviewPage.section2.italic, 'underline': reviewPage.section2.underline}">{{reviewPage.section2.prompt}}</span>
                                                        </template>
                                                    </fitty>
                                                </div>
                                                <div style="display:flex; flex:1; margin-bottom:5px;" v-bind:class="{'bullets-bg': reviewPage.section2.bullets==true}">
                                                    <div class="background lined-bg"  style="display:flex; flex:1">
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- section 3 -->
                                            <div style="display:flex; flex:1; flex-direction:column; border-top:none;" class="edit-section-wrapper" >
                                                <div style="text-align:center; height:50px; margin-top:10px; width:450px; margin-left:auto; margin-right:auto;">
                                                    <fitty  :options="fittyOptions">
                                                        <template v-slot:content>
                                                            <span   v-bind:style="{'font-family':reviewPage.section3.font}" v-bind:class="{'bold': reviewPage.section3.bold, 'italic': reviewPage.section3.italic, 'underline': reviewPage.section3.underline}">{{reviewPage.section3.prompt}}</span>
                                                        </template>
                                                    </fitty>
                                                </div>
                                                <div style="display:flex; flex:1; margin-bottom:5px;" v-bind:class="{'bullets-bg': reviewPage.section3.bullets==true}">
                                                    <div class="background lined-bg"  style="display:flex; flex:1">
                                                    </div>
                                                </div>
                                            </div>
                                         
                                        </div>
                                        <!-- end layout-1:1:1 -->


                                        <!-- layout-1:2xl-->
                                        <div v-if="activeLayout=='layout-1:2xl'" style="display:flex; flex-direction:column; flex:1">
                                            <!-- section 1 -->
                                            <div style="display:flex; flex:1; flex-direction:column; " class="edit-section-wrapper" >
                                                 <div style="text-align:center; height:50px; margin-top:10px; width:450px; margin-left:auto; margin-right:auto;">
                                                    <fitty  :options="fittyOptions">
                                                        <template v-slot:content>
                                                            <span   v-bind:style="{'font-family':reviewPage.section1.font}" v-bind:class="{'bold': reviewPage.section1.bold, 'italic': reviewPage.section1.italic, 'underline': reviewPage.section1.underline}">{{reviewPage.section1.prompt}}</span>
                                                        </template>
                                                    </fitty>
                                                </div>
                                                <div style="display:flex; flex:1; margin-bottom:5px; " v-bind:class="{'bullets-bg': reviewPage.section1.bullets==true}">
                                                    <div class="background lined-bg" style="display:flex; flex:1">
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            
                                            <div style="display:flex; flex:2;">
                                                <!-- section 2 -->
                                                <div style="display:flex; flex:1; flex-direction:column; border-top:none; border-right:none;" class="edit-section-wrapper" >
                                                    <div style="text-align:center; height:50px; margin-top:10px; width:225px; margin-left:auto; margin-right:auto;">
                                                        <fitty  :options="fittyOptionsSm">
                                                            <template v-slot:content>
                                                                <span   v-bind:style="{'font-family':reviewPage.section2.font}" v-bind:class="{'bold': reviewPage.section2.bold, 'italic': reviewPage.section2.italic, 'underline': reviewPage.section2.underline}">{{reviewPage.section2.prompt}}</span>
                                                            </template>
                                                        </fitty>
                                                    </div>
                                                    <div style="display:flex; flex:1; margin-bottom:25px;" v-bind:class="{'bullets-bg': reviewPage.section2.bullets==true}">
                                                        <div class="background lined-bg"  style="display:flex; flex:1">
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- section 3 -->
                                                <div style="display:flex; flex:1; flex-direction:column; border-top:none;" class="edit-section-wrapper" >
                                                    <div style="text-align:center; height:50px; margin-top:10px; width:225px; margin-left:auto; margin-right:auto;">
                                                        <fitty  :options="fittyOptionsSm">
                                                            <template v-slot:content>
                                                                <span   v-bind:style="{'font-family':reviewPage.section3.font}" v-bind:class="{'bold': reviewPage.section3.bold, 'italic': reviewPage.section3.italic, 'underline': reviewPage.section3.underline}">{{reviewPage.section3.prompt}}</span>
                                                            </template>
                                                        </fitty>
                                                    </div>
                                                    <div style="display:flex; flex:1; margin-bottom:25px;" v-bind:class="{'bullets-bg': reviewPage.section3.bullets==true}">
                                                        <div class="background lined-bg"  style="display:flex; flex:1">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- end layout-1:2xl -->

                                        <!-- layout-2xl:1 -->
                                        <div v-if="activeLayout=='layout-2xl:1'" style="display:flex; flex-direction:column; flex:1">
                                            <div style="display:flex; flex:2;">
                                                <!-- section 1 -->
                                                <div style="display:flex; flex:1; flex-direction:column; border-right:none; " class="edit-section-wrapper" >
                                                    <div style="text-align:center; height:50px; margin-top:10px; width:225px; margin-left:auto; margin-right:auto;">
                                                        <fitty  :options="fittyOptionsSm">
                                                            <template v-slot:content>
                                                                <span   v-bind:style="{'font-family':reviewPage.section1.font}" v-bind:class="{'bold': reviewPage.section1.bold, 'italic': reviewPage.section1.italic, 'underline': reviewPage.section1.underline}">{{reviewPage.section1.prompt}}</span>
                                                            </template>
                                                        </fitty>
                                                    </div>
                                                    <div style="display:flex; flex:1; margin-bottom:25px; " v-bind:class="{'bullets-bg': reviewPage.section1.bullets==true}">
                                                        <div class="background lined-bg" style="display:flex; flex:1">
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                
                                            
                                                <!-- section 2 -->
                                                <div style="display:flex; flex:1; flex-direction:column;" class="edit-section-wrapper" >
                                                    <div style="text-align:center; height:50px; margin-top:10px; width:225px; margin-left:auto; margin-right:auto;">
                                                        <fitty  :options="fittyOptionsSm">
                                                            <template v-slot:content>
                                                                <span   v-bind:style="{'font-family':reviewPage.section2.font}" v-bind:class="{'bold': reviewPage.section2.bold, 'italic': reviewPage.section2.italic, 'underline': reviewPage.section2.underline}">{{reviewPage.section2.prompt}}</span>
                                                            </template>
                                                        </fitty>
                                                    </div>
                                                    <div style="display:flex; flex:1; margin-bottom:25px;" v-bind:class="{'bullets-bg': reviewPage.section2.bullets==true}">
                                                        <div class="background lined-bg"  style="display:flex; flex:1">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- section 3 -->
                                            <div style="display:flex; flex:1; flex-direction:column; border-top:none;" class="edit-section-wrapper" >
                                                <div style="text-align:center; height:50px; margin-top:10px; width:450px; margin-left:auto; margin-right:auto;">
                                                        <fitty  :options="fittyOptions">
                                                            <template v-slot:content>
                                                                <span   v-bind:style="{'font-family':reviewPage.section3.font}" v-bind:class="{'bold': reviewPage.section3.bold, 'italic': reviewPage.section3.italic, 'underline': reviewPage.section3.underline}">{{reviewPage.section3.prompt}}</span>
                                                            </template>
                                                        </fitty>
                                                    </div>
                                                <div style="display:flex; flex:1; margin-bottom:5px;" v-bind:class="{'bullets-bg': reviewPage.section3.bullets==true}">
                                                    <div class="background lined-bg"  style="display:flex; flex:1">
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <!-- end layout-2x1:1 -->
                                    </div>
                                    <a href="#" v-on:click="previousPage()" class="pageNav prev"><b-icon icon="chevron-compact-left"></b-icon></a>
                                    <a href="#" v-on:click="nextPage()" class="pageNav next"><b-icon icon="chevron-compact-right"></b-icon></a>
                                 </div>
                            </div>

                            
                        </div>

                        <div class="col-sm-12 col edit-col">
                            <div style="background-color:#fff; padding:10px;">
                            <p>
                                Use the arrows to preview your journal one last time and then add it to the cart!
                            </p>
                            <button v-on:click="addToCart()" class="btn  btn-secondary btn-block"> <b-icon icon="circle-fill" animation="throb"  v-if="adding"></b-icon> Add To Cart</button>
                            </div>

                            <!-- {{this.journalProperties}} -->
                        </div>
                    </div>
                    
                </div>
            </div>

        </div>
    </div>
</template>


<style scoped>
.pad-left{
    padding:20px 20px 20px 40px;
}

.pad-right{
    padding:20px 40px 20px 20px;
}

.pageNav{
    position:absolute;
    top:50%;
    display:inline-block;
    width:50px;
    height:50px;
    background-color:rgba(0,0,0,.5);
    z-index: 99999999;
    font-size:40px;
    line-height:50px;
    color:#fff;
    text-align:center;
}

.pageNav:hover{
   color:#fff;
   background-color:rgba(0,0,0,.8);
}

.prev{
    left:-90px;
}

.next{
    right:-90px;
}
.bold{
    font-weight:bold;
}
.italic{
    font-style: italic;
}
.underline{
    text-decoration: underline;
}


.lined-bg{
    background-image: 
    linear-gradient(0deg, transparent 5em, rgba(212, 212, 212, 0.2) 0, transparent 5.1em), 
    linear-gradient(rgba(162,162,162,.3) 1px, transparent 0);
    background-size: 100% 1.5em, 100% 27px;
    background-repeat: repeat, repeat;
    margin-top:30px;

}

.bullets-bg{
    background-image: 
    url("../assets/checkbox.png");
    background-size:   21.45px;
    background-repeat:   repeat-y;
    background-position:  20px -8px;
    padding-left:50px;
    padding-right:20px;
    
}

.dotted-bg{
    background-image: radial-gradient(rgb(170, 170, 170) 1px, transparent 0);
    background-size: 20px 20px;
    background-position: -9px -9px;
}

.grid-bg{
    background-size: 21.2px 21.2px;
    background-image:
    linear-gradient(to right, rgba(170, 170, 170, .4) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(170, 170, 170, .4) 1px, transparent 1px);
    border: 1px solid #c0c0c070;
}

.btn-edit-section{
    opacity:0;
    transition:all .35s !important;
}
.edit-section-wrapper{
    
    border:1px solid #333;
}


.edit-col{
    max-width:350px;
}

#review-wrapper{
    background-color:#83c9dc;
    padding-top:20px;
}

@media (max-width: 750px) {
  #review-wrapper{
    max-height:550px;
    margin-bottom:10px;
    }

    .edit-col{
        max-width:unset;
    }
}

#review-page{
    transform-origin: top center;
    position: relative;
    padding: 10px;
    border: 1px solid #c0c0c0;
    box-shadow: 0px 0px 10px 10px rgba(0,0,0,.05);
    right: -50%;
    transition: all .5s;
    height:850px;
    width:550px;
    border-radius:10px;
    background-color:#fff;
    display:flex; flex-direction:column;
    background-image: var(--coverImg);
}



#review-page.metal.binding-left::before{
    content:url("../assets/binding-left.png");
    position: absolute;
    left: -25px;
    top: 15px;
    z-index: 999;
}
#review-page.spiral.binding-left::before{
    content:url("../assets/binding-sp-left.png");
    position: absolute;
    left: -25px;
    top: 15px;
    z-index: 999;
}

#review-page.metal.binding-right::before{
    content:url("../assets/binding-right.png");
    position: absolute;
    right: -25px;
    top: 15px;
    z-index: 999;
}
#review-page.spiral.binding-right::before{
    content:url("../assets/binding-sp-right.png");
    position: absolute;
    right: -25px;
    top: 15px;
    z-index: 999;
}


div.layout-title-sm{
    font-size:12px;
    color:#c0c0c0;
}


</style>


<script>
import _ from 'lodash'
import axios from "axios";
  import { uuid } from 'vue-uuid' 

export default {
    props: ['journalProperties'],
    data: function () {
        return {
            adding:false,
            uuid: uuid.v4(), 
            fittyOptions: {
                minSize: 12,
                maxSize: 35,
                multiLine:false,
                observeMutations:
                {
                    subtree: true,
                    childList: true,
                    characterData: true
                }
                
            },
            fittyOptionsSm: {
                minSize: 12,
                maxSize: 20,
                multiLine:true,
                observeMutations:
                {
                    subtree: true,
                    childList: true,
                    characterData: true
                }
                
            },
            reviewPage:{
                layout:null,
                simpleBackground:null,
                section1:{
                    prompt: "Add your prompt",
                    font:"",
                    background:null,
                    bullets:false,
                    bold:false,
                    italic:false,
                    underline:false,
                    isSimple:false
                },
                section2:{
                    prompt: "Add your prompt",
                    font:"",
                    background:null,
                    bullets:false,
                    bold:false,
                    italic:false,
                    underline:false
                },
                section3:{
                    prompt: "Add your prompt",
                    font:"",
                    background:null,
                    bullets:false,
                    bold:false,
                    italic:false,
                    underline:false
                },
                section4:{
                    prompt: "Add your prompt",
                    font:"",
                    background:null,
                    bullets:false,
                    bold:false,
                    italic:false,
                    underline:false
                }
            },
            coverImg:null,
            currentPage:1,
            binding:null,
            promptFilter:null,
            activeLayout:null,
            activeSection:null,
            showLayoutSelect: true,
            showCustom:false,
            linedActive:false,
            gridActive:false,
            dottedActive: false,
            blankActive: false,
        }
    },
    methods:{
        addToCart(){
        this.adding = true;
        var selVariant = document.getElementById('ProductSelect-product-template').value;

            var item ={
                quantity: 1,
                id: selVariant,
                properties: {
                    'Journal Owner': this.journalProperties.journalOwner,
                    'Size': '5.5" x 8.5"',
                    'Paper Type' : "70 lbs",
                    'Pages' : "150 pages",
                    'JournalID' : this.uuid
                }
            }

            axios.post("/cart/add.js", item)
            .then(response => {
                if(response.status == 200){
                    this.journalProperties.journalID = this.uuid;
                    this.journalProperties.coverImage = this.coverImg;
                    const headers = {
                    'Content-Type': 'application/json',
                    'X-Api-Auth-Token': '9e810ce9-4c24-437e-af4a-a2ab746485dd'
                    }

                    axios.post("https://customjournal.w3apps.co/api/journals", this.journalProperties, {headers:headers})
                    .then(response => {
                        if(response.status == 200){
                            window.location.href ="/cart";
                        }
                    })
                }
            })
        },
        resetPages(){
            this.currentPage = 1;
            this.setPageData(this.currentPage);
        },
        previousPage(){
            if (this.currentPage > 1){
                this.currentPage -= 1;
            }
           // console.log(this.currentPage);
            this.setPageData(this.currentPage);
        },
        nextPage(){
            if (this.currentPage < 3){
                this.currentPage += 1;
            }
           // console.log(this.currentPage);
           this.setPageData(this.currentPage);
        },
        getCoverImg(){
                if (this.currentPage==1){
                    return this.coverImg;

                }else{
                    return null;
                }
            },
        setPageData(page){
            
            var props = JSON.parse(JSON.stringify(this.journalProperties));

            if (page==1){
               //cover
               this.activeLayout = null;
           }

           if (page ==2){
               //front page
               this.activeLayout = props.frontPage.layout;
               this.reviewPage = props.frontPage;
               console.log( this.activeLayout);
           }

           if (page == 3){
               //back page
                this.activeLayout = props.backPage.layout;
                this.reviewPage = props.backPage;
               console.log( this.activeLayout);
           }
        },
        setLayout: function(v){
            this.activeLayout = v;
            this.showLayoutSelect = false;
            this.scaleReviewPage();
        },
        scaleReviewPage: _.debounce(function(){
        //set binding image 
       
        var variant = document.getElementById('ProductSelect-product-template').value;
        var productJson =  JSON.parse(document.getElementById('ProductJson-product-template').innerHTML);

        this.coverImg =  productJson.images[0];
        console.log(this.coverImg);

        //console.log(variant);
        //console.log(productJson);

        var valObj = productJson.variants.filter(function(elem){
            if(elem.id == variant) return elem;
        });
        //console.log(valObj);

        if (valObj[0].title=="Metal Wire-O"){
            this.binding = "metal";
        }else{
            this.binding = "spiral";
        }


        var imgWidth = 550;
        var imgHeight = 850;
        
         var $wrapper = document.getElementById("review-wrapper");
         var $el = document.getElementById("review-page");

         var w = $wrapper.clientWidth-50;
         var h =window.innerHeight-50;

         var scaledImg = $el.getBoundingClientRect();
         var scaledH = scaledImg.height;
         var scaledW = scaledImg.width;

            var scale;
            var useDimension ="w";

            if(scaledW > 0 && scaledH > 0){
            
                if(w < h){
                    //check to make sure the height isn't too big
                    if( scaledH < h){
                        //use width
                        useDimension ="w";
                    }else{
                        //use height
                        useDimension ="h";
                    }
                    
                }else{
                    //height is smaller but check width
                    if(scaledW <= w){
                    //use height 
                        useDimension ="h";
                    }else{
                        //use width
                        useDimension ="w";
                    }
                
                }

                if (useDimension == "w"){
                    scale = (w / imgWidth);
                    //if (scale <0){scale=0.3}
                    this.changeScale(scale);
                }else{
                    scale = (h / imgHeight);
                // if (scale <0){scale=0.3}
                    this.changeScale(scale);
                }
    
            }

        }, 100),
        changeScale(newScale){
           // console.log(newScale);
            var div =  document.getElementById("review-page");
            div.style.transform = " translate(-50%) scale(" + newScale + ")";
            
        },
        setBackground: function(style){
            this.showCustom = false;
            this.linedActive = false;
            this.blankActive = false;
            this.dottedActive = false;
            this.gridActive = false;
            this.reviewPage.section1.prompt = null;

            if (style == "lined-bg"){
                this.linedActive = true;
                
            }
            if (style == "blank"){
                this.blankActive = true;
               
            }
            if (style == "dotted-bg"){
                this.dottedActive = true;
                
            }
            if (style == "grid-bg"){
                this.gridActive = true;
            }
        }
    },
    created() {
        window.addEventListener("resize", this.scaleReviewPage);
    },
    destroyed() {
        window.removeEventListener("resize", this.scaleReviewPage);
    }
}
</script>