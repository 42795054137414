<template>
    <div>
        <b-modal id="modal-error-back" modal-class="myClass"  ok-only>
            <div>
                Make sure that prompts have been selected for all sections before proceeding!
            </div>
        </b-modal>

        <div>
            
            <div v-if="showLayoutSelect">
                <div class="container">
            <div class="row" style="margin-bottom:10px;">
                <div class="col-xs-12 col-sm-6"><h3>Choose Your Layout</h3></div>
                <div class="col-xs-12 col-sm-6">
                    <button class="btn btn-sm btn-secondary" style="float:right; margin-left:10px;" v-on:click="duplicateFront()">Duplicate Front</button> 
                    <button class="btn btn-sm btn-secondary" style="float:right;" v-on:click="blankPage()">Leave Blank</button>
                </div>
  
            </div>
            
            <div class="row" >
                <div class="col-10 offset-1 offset-lg-0 col-lg-3">
                     <b-card header="Layout 1" header-tag="header" class="layout-card" v-bind:class="{ selected: activeLayout=='layout-1' }" v-on:click="setLayout('layout-1')" >
                        <div class="layout-title-sm">Title</div>
                        <b-skeleton width="85%"></b-skeleton>
                        <b-skeleton width="55%"></b-skeleton>
                        <b-skeleton width="70%"></b-skeleton>
                        <b-skeleton width="85%"></b-skeleton>
                        <b-skeleton width="55%"></b-skeleton>
                        <b-skeleton width="70%"></b-skeleton>
                        <b-skeleton width="70%"></b-skeleton>
                        <b-skeleton width="70%"></b-skeleton>
                        <b-skeleton width="85%"></b-skeleton>
                        <b-skeleton width="55%"></b-skeleton>
                    </b-card>
                </div>
                <div class="col-10 offset-1 offset-lg-0 col-lg-3">
                     <b-card header="Layout 1:1" header-tag="header" class="layout-card" v-bind:class="{ selected: activeLayout=='layout-1:1' }" v-on:click="setLayout('layout-1:1')">
                         <div class="layout-title-sm">Title</div>
                         <b-skeleton width="85%"></b-skeleton>
                        <b-skeleton width="55%"></b-skeleton>
                        <b-skeleton width="70%"></b-skeleton>
                        <b-skeleton width="55%"></b-skeleton>
                        <div class="layout-divider"></div>
                        <div class="layout-title-sm">Title</div>
                        <b-skeleton width="85%"></b-skeleton>
                        <b-skeleton width="55%"></b-skeleton>
                        <b-skeleton width="55%"></b-skeleton>
                        <b-skeleton width="70%"></b-skeleton>
                    </b-card>
                </div>
                <div class="col-10 offset-1 offset-lg-0 col-lg-3">
                     <b-card header="Layout 1:2" header-tag="header" class="layout-card" v-bind:class="{ selected: activeLayout=='layout-1:2' }" v-on:click="setLayout('layout-1:2')">
                         <div class="layout-title-sm">Title</div>
                         <b-skeleton width="85%"></b-skeleton>
                        <b-skeleton width="55%"></b-skeleton>
                        <b-skeleton width="55%"></b-skeleton>
                        <b-skeleton width="70%"></b-skeleton>
                        <div class="layout-divider"></div>
                        <div class="row">
                            <div class="col-6  layout-divider-right">
                                <div class="layout-title-sm">Title</div>
                                <b-skeleton width="85%"></b-skeleton>
                                <b-skeleton width="55%"></b-skeleton>
                                <b-skeleton width="55%"></b-skeleton>
                                <b-skeleton width="70%"></b-skeleton>
                            </div>
                            <div class="col-6">
                                <div class="layout-title-sm">Title</div>
                                <b-skeleton width="85%"></b-skeleton>
                                <b-skeleton width="55%"></b-skeleton>
                                <b-skeleton width="55%"></b-skeleton>
                                <b-skeleton width="70%"></b-skeleton>
                            </div>
                        </div>
                    </b-card>
                </div>
                <div class="col-10 offset-1 offset-lg-0 col-lg-3">
                     <b-card header="Layout 2:1" header-tag="header" class="layout-card" v-bind:class="{ selected: activeLayout=='layout-2:1' }" v-on:click="setLayout('layout-2:1')">
                         <div class="row">
                            <div class="col-6  layout-divider-right">
                                <div class="layout-title-sm">Title</div>
                                <b-skeleton width="85%"></b-skeleton>
                                <b-skeleton width="55%"></b-skeleton>
                                <b-skeleton width="55%"></b-skeleton>
                                <b-skeleton width="70%"></b-skeleton>
                            </div>
                            <div class="col-6">
                                <div class="layout-title-sm">Title</div>
                                <b-skeleton width="85%"></b-skeleton>
                                <b-skeleton width="55%"></b-skeleton>
                                <b-skeleton width="70%"></b-skeleton>
                                <b-skeleton width="55%"></b-skeleton>
                            </div>
                        </div>
                        <div class="layout-divider"></div>
                        <div class="layout-title-sm">Title</div>
                        <b-skeleton width="85%"></b-skeleton>
                        <b-skeleton width="55%"></b-skeleton>
                        <b-skeleton width="55%"></b-skeleton>
                        <b-skeleton width="70%"></b-skeleton>
                        
                    </b-card>
                </div>
              
            </div>
            
            <div class="row">
                <div class="col-10 offset-1 offset-lg-0 col-lg-3">
                     <b-card header="Layout 2:2" header-tag="header" class="layout-card" v-bind:class="{ selected: activeLayout=='layout-2:2' }" v-on:click="setLayout('layout-2:2')">
                        <div class="row">
                            <div class="col-6  layout-divider-right">
                                <div class="layout-title-sm">Title</div>
                                <b-skeleton width="85%"></b-skeleton>
                                <b-skeleton width="55%"></b-skeleton>
                                <b-skeleton width="70%"></b-skeleton>
                                <b-skeleton width="70%"></b-skeleton>
                            </div>
                            <div class="col-6">
                                <div class="layout-title-sm">Title</div>
                                <b-skeleton width="85%"></b-skeleton>
                                <b-skeleton width="55%"></b-skeleton>
                                <b-skeleton width="70%"></b-skeleton>
                                <b-skeleton width="70%"></b-skeleton>
                            </div>
                        </div>
                        <div class="layout-divider"></div>
                        <div class="row">
                            <div class="col-6  layout-divider-right">
                                <div class="layout-title-sm">Title</div>
                                <b-skeleton width="85%"></b-skeleton>
                                <b-skeleton width="55%"></b-skeleton>
                                <b-skeleton width="70%"></b-skeleton>
                                <b-skeleton width="70%"></b-skeleton>
                            </div>
                            <div class="col-6">
                                <div class="layout-title-sm">Title</div>
                                <b-skeleton width="85%"></b-skeleton>
                                <b-skeleton width="55%"></b-skeleton>
                                <b-skeleton width="70%"></b-skeleton>
                                <b-skeleton width="70%"></b-skeleton>
                            </div>
                        </div>
                    </b-card>
                </div>
                <div class="col-10 offset-1 offset-lg-0 col-lg-3">
                     <b-card header="Layout 1:1:1" header-tag="header" class="layout-card" v-bind:class="{ selected: activeLayout=='layout-1:1:1' }" v-on:click="setLayout('layout-1:1:1')">
                         <div class="layout-title-sm">Title</div>
                         <b-skeleton width="85%"></b-skeleton>
                        <b-skeleton width="55%"></b-skeleton>
                        
                        <div class="layout-divider"></div>
                        <div class="layout-title-sm">Title</div>
                        <b-skeleton width="85%"></b-skeleton>
                        <b-skeleton width="55%"></b-skeleton>
                        
                        <div class="layout-divider"></div>
                        <div class="layout-title-sm">Title</div>
                        <b-skeleton width="85%"></b-skeleton>
                        <b-skeleton width="55%"></b-skeleton>
                        
                    </b-card>
                </div>
                <div class="col-10 offset-1 offset-lg-0 col-lg-3">
                     <b-card header="Layout 1:2xl" header-tag="header" class="layout-card" v-bind:class="{ selected: activeLayout=='layout-1:2xl' }" v-on:click="setLayout('layout-1:2xl')">
                         <div class="layout-title-sm">Title</div>
                          <b-skeleton width="85%"></b-skeleton>
                        <b-skeleton width="55%"></b-skeleton>
                        
                        <div class="layout-divider"></div>
                        <div class="row">
                            <div class="col-6  layout-divider-right">
                                <div class="layout-title-sm">Title</div>
                                <b-skeleton width="85%"></b-skeleton>
                                <b-skeleton width="70%"></b-skeleton>
                                <b-skeleton width="55%"></b-skeleton>
                                <b-skeleton width="70%"></b-skeleton>
                                <b-skeleton width="55%"></b-skeleton>
                                <b-skeleton width="70%"></b-skeleton>
                            </div>
                            <div class="col-6">
                                <div class="layout-title-sm">Title</div>
                                <b-skeleton width="85%"></b-skeleton>
                                <b-skeleton width="70%"></b-skeleton>
                                <b-skeleton width="55%"></b-skeleton>
                                <b-skeleton width="70%"></b-skeleton>
                                <b-skeleton width="55%"></b-skeleton>
                                <b-skeleton width="70%"></b-skeleton>
                            </div>
                        </div>
                    </b-card>
                </div>
                <div class="col-10 offset-1 offset-lg-0 col-lg-3">
                     <b-card header="Layout 2xl:1" header-tag="header" class="layout-card" v-bind:class="{ selected: activeLayout=='layout-2xl:1' }" v-on:click="setLayout('layout-2xl:1')">
                        
                        <div class="row">
                            <div class="col-6  layout-divider-right">
                                <div class="layout-title-sm">Title</div>
                                <b-skeleton width="85%"></b-skeleton>
                                <b-skeleton width="55%"></b-skeleton>
                                <b-skeleton width="70%"></b-skeleton>
                                <b-skeleton width="70%"></b-skeleton>
                                <b-skeleton width="55%"></b-skeleton>
                                <b-skeleton width="70%"></b-skeleton>
                            </div>
                            <div class="col-6">
                                <div class="layout-title-sm">Title</div>
                                <b-skeleton width="85%"></b-skeleton>
                                <b-skeleton width="55%"></b-skeleton>
                                <b-skeleton width="70%"></b-skeleton>
                                <b-skeleton width="70%"></b-skeleton>
                                <b-skeleton width="55%"></b-skeleton>
                                <b-skeleton width="70%"></b-skeleton>
                            </div>
                        </div>
                        <div class="layout-divider"></div>
                        <div class="layout-title-sm">Title</div>
                        <b-skeleton width="85%"></b-skeleton>
                        <b-skeleton width="55%"></b-skeleton>
                        
                    </b-card>
                </div>
              
            </div>
                </div>
            </div>

            <!-- select prompts -->
            <div v-if="activeLayout != null && showLayoutSelect==false">
               
                <h3>Select Your Prompts</h3>
                
                <div>
                    
                    <div class="row">
                        <div class="col">
                            <div id="back-wrapper" v-on:click="clearEdit($event)">
                                <div id="back-page" v-bind:class="{'metal' : binding=='metal', 'spiral': binding=='spiral'}"  style="width: 550px; height: 850px; transform:scale(0.30) translateX(-50%); " >
                                    <div style="padding:20px 40px 20px 20px; display:flex; flex-direction:column; flex:1;">
                                                              <!-- layout-1 -->
                                        <div v-if="activeLayout=='layout-1'" style="display:flex; flex-direction:column; flex:1" >
                                            <!-- section 1 -->
                                            <div style="display:flex; flex:1; flex-direction:column; "  v-bind:class="{'active' : activeSection==1, 'edit-section-wrapper': showCustom==true, 'edit-section-wrapper-no-border': showCustom==false}" v-on:click="allowCustom()">
                                            <div style="text-align:center; height:50px; margin-top:10px; width:450px; margin-left:auto; margin-right:auto;">
                                            <fitty  :options="fittyOptions">
                                                <template v-slot:content>
                                                    <span   v-bind:style="{'font-family':backPage.section1.font}" v-bind:class="{'bold': backPage.section1.bold, 'italic': backPage.section1.italic, 'underline': backPage.section1.underline}">{{backPage.section1.prompt}}</span>
                                                </template>
                                            </fitty>
                                            </div>
                                            <div style="display:flex; flex:1; margin-bottom:25px" v-bind:class="{'bullets-bg': backPage.section1.bullets==true}">
                                                <div class="background" v-bind:class="{'grid-bg': gridActive, 'dotted-bg': dottedActive, 'lined-bg': linedActive || backPage.section1.background == 'lined-bg'}" style="display:flex; flex:1">
                                                        <button  class="btn btn-primary btn-edit-section" v-on:click="allowCustom()" style="position:relative; margin:auto; ">Click to Edit</button>
                                                </div>
                                            </div>
                                            </div>
                                        </div>

                                        <!-- layout-1:1 -->
                                        <div v-if="activeLayout=='layout-1:1'" style="display:flex; flex-direction:column; flex:1">
                                            <!-- section 1 -->
                                            <div style="display:flex; flex:1; flex-direction:column; " class="edit-section-wrapper" v-bind:class="{'active' : activeSection==1}" v-on:click="setActiveSection(1)">
                                                <div style="text-align:center; height:50px; margin-top:10px; width:450px; margin-left:auto; margin-right:auto;">
                                                    <fitty  :options="fittyOptions">
                                                        <template v-slot:content>
                                                            <span   v-bind:style="{'font-family':backPage.section1.font}" v-bind:class="{'bold': backPage.section1.bold, 'italic': backPage.section1.italic, 'underline': backPage.section1.underline}">{{backPage.section1.prompt}}</span>
                                                        </template>
                                                    </fitty>
                                                </div>
                                                <div style="display:flex; flex:1; margin-bottom:25px; " v-bind:class="{'bullets-bg': backPage.section1.bullets==true}">
                                                    <div class="background lined-bg" style="display:flex; flex:1">
                                                        <button  class="btn btn-primary btn-edit-section" v-on:click="setActiveSection(1)" style="position:relative; margin:auto; ">Click to Edit</button>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            
                                            <!-- section 2 -->
                                            <div style="display:flex; flex:1; flex-direction:column; border-top:none;" class="edit-section-wrapper" v-bind:class="{'active' : activeSection==2}" v-on:click="setActiveSection(2)">
                                                <div style="text-align:center; height:50px; margin-top:10px; width:450px; margin-left:auto; margin-right:auto;">
                                                    <fitty  :options="fittyOptions">
                                                        <template v-slot:content>
                                                            <span   v-bind:style="{'font-family':backPage.section2.font}" v-bind:class="{'bold': backPage.section2.bold, 'italic': backPage.section2.italic, 'underline': backPage.section2.underline}">{{backPage.section2.prompt}}</span>
                                                        </template>
                                                    </fitty>
                                                </div>
                                                <div style="display:flex; flex:1; margin-bottom:25px;" v-bind:class="{'bullets-bg': backPage.section2.bullets==true}">
                                                    <div class="background lined-bg"  style="display:flex; flex:1">
                                                        <button  class="btn btn-primary btn-edit-section"  v-on:click="setActiveSection(2)"  style="position:relative; margin:auto;">Click to Edit</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- end layout-1:1 -->


                                        <!-- layout-1:2 -->
                                        <div v-if="activeLayout=='layout-1:2'" style="display:flex; flex-direction:column; flex:1">
                                            <!-- section 1 -->
                                            <div style="display:flex; flex:1; flex-direction:column; " class="edit-section-wrapper" v-bind:class="{'active' : activeSection==1}" v-on:click="setActiveSection(1)">
                                                <div style="text-align:center; height:50px; margin-top:10px; width:450px; margin-left:auto; margin-right:auto;">
                                                    <fitty  :options="fittyOptions">
                                                        <template v-slot:content>
                                                            <span   v-bind:style="{'font-family':backPage.section1.font}" v-bind:class="{'bold': backPage.section1.bold, 'italic': backPage.section1.italic, 'underline': backPage.section1.underline}">{{backPage.section1.prompt}}</span>
                                                        </template>
                                                    </fitty>
                                                </div>
                                                <div style="display:flex; flex:1; margin-bottom:25px; " v-bind:class="{'bullets-bg': backPage.section1.bullets==true}">
                                                    <div class="background lined-bg" style="display:flex; flex:1">
                                                        <button  class="btn btn-primary btn-edit-section" v-on:click="setActiveSection(1)" style="position:relative; margin:auto; ">Click to Edit</button>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            
                                            <div style="display:flex; flex:1;">
                                                <!-- section 2 -->
                                                <div style="display:flex; flex:1; flex-direction:column; border-top:none; border-right:none;" class="edit-section-wrapper" v-bind:class="{'active' : activeSection==2}" v-on:click="setActiveSection(2)">
                                                    <div style="text-align:center; height:50px; margin-top:10px; width:225px; margin-left:auto; margin-right:auto;">
                                                    <fitty  :options="fittyOptionsSm">
                                                        <template v-slot:content>
                                                            <span   v-bind:style="{'font-family':backPage.section2.font}" v-bind:class="{'bold': backPage.section2.bold, 'italic': backPage.section2.italic, 'underline': backPage.section2.underline}">{{backPage.section2.prompt}}</span>
                                                        </template>
                                                    </fitty>
                                                    </div>
                                                    <div style="display:flex; flex:1; margin-bottom:25px;" v-bind:class="{'bullets-bg': backPage.section2.bullets==true}">
                                                        <div class="background lined-bg"  style="display:flex; flex:1">
                                                            <button  class="btn btn-primary btn-edit-section"  v-on:click="setActiveSection(2)"  style="position:relative; margin:auto;">Click to Edit</button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- section 3 -->
                                                <div style="display:flex; flex:1; flex-direction:column; border-top:none;" class="edit-section-wrapper" v-bind:class="{'active' : activeSection==3}" v-on:click="setActiveSection(3)">
                                                    <div style="text-align:center; height:50px; margin-top:10px; width:225px; margin-left:auto; margin-right:auto;">
                                                        <fitty  :options="fittyOptionsSm">
                                                            <template v-slot:content>
                                                                <span   v-bind:style="{'font-family':backPage.section3.font}" v-bind:class="{'bold': backPage.section3.bold, 'italic': backPage.section3.italic, 'underline': backPage.section3.underline}">{{backPage.section3.prompt}}</span>
                                                            </template>
                                                        </fitty>
                                                    </div>
                                                    <div style="display:flex; flex:1; margin-bottom:25px;" v-bind:class="{'bullets-bg': backPage.section3.bullets==true}">
                                                        <div class="background lined-bg"  style="display:flex; flex:1">
                                                            <button  class="btn btn-primary btn-edit-section"  v-on:click="setActiveSection(3)"  style="position:relative; margin:auto;">Click to Edit</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- end layout-1:2 -->

                                        <!-- layout-2:1 -->
                                        <div v-if="activeLayout=='layout-2:1'" style="display:flex; flex-direction:column; flex:1">
                                            <div style="display:flex; flex:1;">
                                                <!-- section 1 -->
                                                <div style="display:flex; flex:1; flex-direction:column; border-right:none; " class="edit-section-wrapper" v-bind:class="{'active' : activeSection==1}" v-on:click="setActiveSection(1)">
                                                    <div style="text-align:center; height:50px; margin-top:10px; width:225px; margin-left:auto; margin-right:auto;">
                                                        <fitty  :options="fittyOptionsSm">
                                                            <template v-slot:content>
                                                                <span   v-bind:style="{'font-family':backPage.section1.font}" v-bind:class="{'bold': backPage.section1.bold, 'italic': backPage.section1.italic, 'underline': backPage.section1.underline}">{{backPage.section1.prompt}}</span>
                                                            </template>
                                                        </fitty>
                                                    </div>

                                                    <div style="display:flex; flex:1; margin-bottom:25px; " v-bind:class="{'bullets-bg': backPage.section1.bullets==true}">
                                                        <div class="background lined-bg" style="display:flex; flex:1">
                                                            <button  class="btn btn-primary btn-edit-section" v-on:click="setActiveSection(1)" style="position:relative; margin:auto; ">Click to Edit</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                
                                            
                                                <!-- section 2 -->
                                                <div style="display:flex; flex:1; flex-direction:column;" class="edit-section-wrapper" v-bind:class="{'active' : activeSection==2}" v-on:click="setActiveSection(2)">
                                                    <div style="text-align:center; height:50px; margin-top:10px; width:225px; margin-left:auto; margin-right:auto;">
                                                        <fitty  :options="fittyOptionsSm">
                                                            <template v-slot:content>
                                                                <span   v-bind:style="{'font-family':backPage.section2.font}" v-bind:class="{'bold': backPage.section2.bold, 'italic': backPage.section2.italic, 'underline': backPage.section2.underline}">{{backPage.section2.prompt}}</span>
                                                            </template>
                                                        </fitty>
                                                    </div>
                                                    <div style="display:flex; flex:1; margin-bottom:25px;" v-bind:class="{'bullets-bg': backPage.section2.bullets==true}">
                                                        <div class="background lined-bg"  style="display:flex; flex:1">
                                                            <button  class="btn btn-primary btn-edit-section"  v-on:click="setActiveSection(2)"  style="position:relative; margin:auto;">Click to Edit</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- section 3 -->
                                            <div style="display:flex; flex:1; flex-direction:column; border-top:none;" class="edit-section-wrapper" v-bind:class="{'active' : activeSection==3}" v-on:click="setActiveSection(3)">
                                                <div style="text-align:center; height:50px; margin-top:10px; width:450px; margin-left:auto; margin-right:auto;">
                                                    <fitty  :options="fittyOptions">
                                                        <template v-slot:content>
                                                            <span   v-bind:style="{'font-family':backPage.section3.font}" v-bind:class="{'bold': backPage.section3.bold, 'italic': backPage.section3.italic, 'underline': backPage.section3.underline}">{{backPage.section3.prompt}}</span>
                                                        </template>
                                                    </fitty>
                                                </div>
                                                <div style="display:flex; flex:1; margin-bottom:25px;" v-bind:class="{'bullets-bg': backPage.section3.bullets==true}">
                                                    <div class="background lined-bg"  style="display:flex; flex:1">
                                                        <button  class="btn btn-primary btn-edit-section"  v-on:click="setActiveSection(3)"  style="position:relative; margin:auto;">Click to Edit</button>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <!-- end layout-2:1 -->


                                        <!-- layout-2:2 -->
                                        <div v-if="activeLayout=='layout-2:2'" style="display:flex; flex-direction:column; flex:1">
                                            <div style="display:flex; flex:1;">
                                                <!-- section 1 -->
                                                <div style="display:flex; flex:1; flex-direction:column; border-right:none; " class="edit-section-wrapper" v-bind:class="{'active' : activeSection==1}" v-on:click="setActiveSection(1)">
                                                    <div style="text-align:center; height:50px; margin-top:10px; width:225px; margin-left:auto; margin-right:auto;">
                                                        <fitty  :options="fittyOptionsSm">
                                                            <template v-slot:content>
                                                                <span   v-bind:style="{'font-family':backPage.section1.font}" v-bind:class="{'bold': backPage.section1.bold, 'italic': backPage.section1.italic, 'underline': backPage.section1.underline}">{{backPage.section1.prompt}}</span>
                                                            </template>
                                                        </fitty>
                                                    </div>
                                                    <div style="display:flex; flex:1; margin-bottom:25px; " v-bind:class="{'bullets-bg': backPage.section1.bullets==true}">
                                                        <div class="background lined-bg" style="display:flex; flex:1">
                                                            <button  class="btn btn-primary btn-edit-section" v-on:click="setActiveSection(1)" style="position:relative; margin:auto; ">Click to Edit</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                
                                            
                                                <!-- section 2 -->
                                                <div style="display:flex; flex:1; flex-direction:column;" class="edit-section-wrapper" v-bind:class="{'active' : activeSection==2}" v-on:click="setActiveSection(2)">
                                                    <div style="text-align:center; height:50px; margin-top:10px; width:225px; margin-left:auto; margin-right:auto;">
                                                        <fitty  :options="fittyOptionsSm">
                                                            <template v-slot:content>
                                                                <span   v-bind:style="{'font-family':backPage.section2.font}" v-bind:class="{'bold': backPage.section2.bold, 'italic': backPage.section2.italic, 'underline': backPage.section2.underline}">{{backPage.section2.prompt}}</span>
                                                            </template>
                                                        </fitty>
                                                    </div>
                                                    <div style="display:flex; flex:1; margin-bottom:25px;" v-bind:class="{'bullets-bg': backPage.section2.bullets==true}">
                                                        <div class="background lined-bg"  style="display:flex; flex:1">
                                                            <button  class="btn btn-primary btn-edit-section"  v-on:click="setActiveSection(2)"  style="position:relative; margin:auto;">Click to Edit</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div style="display:flex; flex:1;">
                                                <!-- section 3 -->
                                                <div style="display:flex; flex:1; flex-direction:column; border-top:none; border-right:none;" class="edit-section-wrapper" v-bind:class="{'active' : activeSection==3}" v-on:click="setActiveSection(3)">
                                                    <div style="text-align:center; height:50px; margin-top:10px; width:225px; margin-left:auto; margin-right:auto;">
                                                        <fitty  :options="fittyOptionsSm">
                                                            <template v-slot:content>
                                                                <span   v-bind:style="{'font-family':backPage.section3.font}" v-bind:class="{'bold': backPage.section3.bold, 'italic': backPage.section3.italic, 'underline': backPage.section3.underline}">{{backPage.section3.prompt}}</span>
                                                            </template>
                                                        </fitty>
                                                    </div>
                                                    <div style="display:flex; flex:1; margin-bottom:25px;" v-bind:class="{'bullets-bg': backPage.section3.bullets==true}">
                                                        <div class="background lined-bg"  style="display:flex; flex:1">
                                                            <button  class="btn btn-primary btn-edit-section"  v-on:click="setActiveSection(3)"  style="position:relative; margin:auto;">Click to Edit</button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- section 4 -->
                                                <div style="display:flex; flex:1; flex-direction:column; border-top:none;" class="edit-section-wrapper" v-bind:class="{'active' : activeSection==4}" v-on:click="setActiveSection(4)">
                                                    <div style="text-align:center; height:50px; margin-top:10px; width:225px; margin-left:auto; margin-right:auto;">
                                                        <fitty  :options="fittyOptionsSm">
                                                            <template v-slot:content>
                                                                <span   v-bind:style="{'font-family':backPage.section4.font}" v-bind:class="{'bold': backPage.section4.bold, 'italic': backPage.section4.italic, 'underline': backPage.section4.underline}">{{backPage.section4.prompt}}</span>
                                                            </template>
                                                        </fitty>
                                                    </div>
                                                    <div style="display:flex; flex:1; margin-bottom:25px;" v-bind:class="{'bullets-bg': backPage.section4.bullets==true}">
                                                        <div class="background lined-bg"  style="display:flex; flex:1">
                                                            <button  class="btn btn-primary btn-edit-section"  v-on:click="setActiveSection(4)"  style="position:relative; margin:auto;">Click to Edit</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- end layout-2:2 -->

                                        <!-- layout-1:1:1 -->
                                        <div v-if="activeLayout=='layout-1:1:1'" style="display:flex; flex-direction:column; flex:1">
                                            <!-- section 1 -->
                                            <div style="display:flex; flex:1; flex-direction:column; " class="edit-section-wrapper" v-bind:class="{'active' : activeSection==1}" v-on:click="setActiveSection(1)">
                                                <div style="text-align:center; height:50px; margin-top:10px; width:450px; margin-left:auto; margin-right:auto;">
                                                    <fitty  :options="fittyOptions">
                                                        <template v-slot:content>
                                                            <span   v-bind:style="{'font-family':backPage.section1.font}" v-bind:class="{'bold': backPage.section1.bold, 'italic': backPage.section1.italic, 'underline': backPage.section1.underline}">{{backPage.section1.prompt}}</span>
                                                        </template>
                                                    </fitty>
                                                </div>
                                                <div style="display:flex; flex:1; margin-bottom:5px; " v-bind:class="{'bullets-bg': backPage.section1.bullets==true}">
                                                    <div class="background lined-bg" style="display:flex; flex:1">
                                                        <button  class="btn btn-primary btn-edit-section" v-on:click="setActiveSection(1)" style="position:relative; margin:auto; ">Click to Edit</button>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            
                                            <!-- section 2 -->
                                            <div style="display:flex; flex:1; flex-direction:column; border-top:none; " class="edit-section-wrapper" v-bind:class="{'active' : activeSection==2}" v-on:click="setActiveSection(2)">
                                                <div style="text-align:center; height:50px; margin-top:10px; width:450px; margin-left:auto; margin-right:auto;">
                                                    <fitty  :options="fittyOptions">
                                                        <template v-slot:content>
                                                            <span   v-bind:style="{'font-family':backPage.section2.font}" v-bind:class="{'bold': backPage.section2.bold, 'italic': backPage.section2.italic, 'underline': backPage.section2.underline}">{{backPage.section2.prompt}}</span>
                                                        </template>
                                                    </fitty>
                                                </div>
                                                <div style="display:flex; flex:1; margin-bottom:5px;" v-bind:class="{'bullets-bg': backPage.section2.bullets==true}">
                                                    <div class="background lined-bg"  style="display:flex; flex:1">
                                                        <button  class="btn btn-primary btn-edit-section"  v-on:click="setActiveSection(2)"  style="position:relative; margin:auto;">Click to Edit</button>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- section 3 -->
                                            <div style="display:flex; flex:1; flex-direction:column; border-top:none;" class="edit-section-wrapper" v-bind:class="{'active' : activeSection==3}" v-on:click="setActiveSection(3)">
                                                <div style="text-align:center; height:50px; margin-top:10px; width:450px; margin-left:auto; margin-right:auto;">
                                                    <fitty  :options="fittyOptions">
                                                        <template v-slot:content>
                                                            <span   v-bind:style="{'font-family':backPage.section3.font}" v-bind:class="{'bold': backPage.section3.bold, 'italic': backPage.section3.italic, 'underline': backPage.section3.underline}">{{backPage.section3.prompt}}</span>
                                                        </template>
                                                    </fitty>
                                                </div>
                                                <div style="display:flex; flex:1; margin-bottom:5px;" v-bind:class="{'bullets-bg': backPage.section3.bullets==true}">
                                                    <div class="background lined-bg"  style="display:flex; flex:1">
                                                        <button  class="btn btn-primary btn-edit-section"  v-on:click="setActiveSection(3)"  style="position:relative; margin:auto;">Click to Edit</button>
                                                    </div>
                                                </div>
                                            </div>
                                         
                                        </div>
                                        <!-- end layout-1:1:1 -->


                                        <!-- layout-1:2xl-->
                                        <div v-if="activeLayout=='layout-1:2xl'" style="display:flex; flex-direction:column; flex:1">
                                            <!-- section 1 -->
                                            <div style="display:flex; flex:1; flex-direction:column; " class="edit-section-wrapper" v-bind:class="{'active' : activeSection==1}" v-on:click="setActiveSection(1)">
                                                 <div style="text-align:center; height:50px; margin-top:10px; width:450px; margin-left:auto; margin-right:auto;">
                                                    <fitty  :options="fittyOptions">
                                                        <template v-slot:content>
                                                            <span   v-bind:style="{'font-family':backPage.section1.font}" v-bind:class="{'bold': backPage.section1.bold, 'italic': backPage.section1.italic, 'underline': backPage.section1.underline}">{{backPage.section1.prompt}}</span>
                                                        </template>
                                                    </fitty>
                                                </div>
                                                <div style="display:flex; flex:1; margin-bottom:5px; " v-bind:class="{'bullets-bg': backPage.section1.bullets==true}">
                                                    <div class="background lined-bg" style="display:flex; flex:1">
                                                        <button  class="btn btn-primary btn-edit-section" v-on:click="setActiveSection(1)" style="position:relative; margin:auto; ">Click to Edit</button>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            
                                            <div style="display:flex; flex:2;">
                                                <!-- section 2 -->
                                                <div style="display:flex; flex:1; flex-direction:column; border-top:none; border-right:none;" class="edit-section-wrapper" v-bind:class="{'active' : activeSection==2}" v-on:click="setActiveSection(2)">
                                                    <div style="text-align:center; height:50px; margin-top:10px; width:225px; margin-left:auto; margin-right:auto;">
                                                        <fitty  :options="fittyOptionsSm">
                                                            <template v-slot:content>
                                                                <span   v-bind:style="{'font-family':backPage.section2.font}" v-bind:class="{'bold': backPage.section2.bold, 'italic': backPage.section2.italic, 'underline': backPage.section2.underline}">{{backPage.section2.prompt}}</span>
                                                            </template>
                                                        </fitty>
                                                    </div>
                                                    <div style="display:flex; flex:1; margin-bottom:25px;" v-bind:class="{'bullets-bg': backPage.section2.bullets==true}">
                                                        <div class="background lined-bg"  style="display:flex; flex:1">
                                                            <button  class="btn btn-primary btn-edit-section"  v-on:click="setActiveSection(2)"  style="position:relative; margin:auto;">Click to Edit</button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- section 3 -->
                                                <div style="display:flex; flex:1; flex-direction:column; border-top:none;" class="edit-section-wrapper" v-bind:class="{'active' : activeSection==3}" v-on:click="setActiveSection(3)">
                                                    <div style="text-align:center; height:50px; margin-top:10px; width:225px; margin-left:auto; margin-right:auto;">
                                                        <fitty  :options="fittyOptionsSm">
                                                            <template v-slot:content>
                                                                <span   v-bind:style="{'font-family':backPage.section3.font}" v-bind:class="{'bold': backPage.section3.bold, 'italic': backPage.section3.italic, 'underline': backPage.section3.underline}">{{backPage.section3.prompt}}</span>
                                                            </template>
                                                        </fitty>
                                                    </div>
                                                    <div style="display:flex; flex:1; margin-bottom:25px;" v-bind:class="{'bullets-bg': backPage.section3.bullets==true}">
                                                        <div class="background lined-bg"  style="display:flex; flex:1">
                                                            <button  class="btn btn-primary btn-edit-section"  v-on:click="setActiveSection(3)"  style="position:relative; margin:auto;">Click to Edit</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- end layout-1:2xl -->

                                        <!-- layout-2xl:1 -->
                                        <div v-if="activeLayout=='layout-2xl:1'" style="display:flex; flex-direction:column; flex:1">
                                            <div style="display:flex; flex:2;">
                                                <!-- section 1 -->
                                                <div style="display:flex; flex:1; flex-direction:column; border-right:none; " class="edit-section-wrapper" v-bind:class="{'active' : activeSection==1}" v-on:click="setActiveSection(1)">
                                                    <div style="text-align:center; height:50px; margin-top:10px; width:225px; margin-left:auto; margin-right:auto;">
                                                        <fitty  :options="fittyOptionsSm">
                                                            <template v-slot:content>
                                                                <span   v-bind:style="{'font-family':backPage.section1.font}" v-bind:class="{'bold': backPage.section1.bold, 'italic': backPage.section1.italic, 'underline': backPage.section1.underline}">{{backPage.section1.prompt}}</span>
                                                            </template>
                                                        </fitty>
                                                    </div>
                                                    <div style="display:flex; flex:1; margin-bottom:25px; " v-bind:class="{'bullets-bg': backPage.section1.bullets==true}">
                                                        <div class="background lined-bg" style="display:flex; flex:1">
                                                            <button  class="btn btn-primary btn-edit-section" v-on:click="setActiveSection(1)" style="position:relative; margin:auto; ">Click to Edit</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                
                                            
                                                <!-- section 2 -->
                                                <div style="display:flex; flex:1; flex-direction:column;" class="edit-section-wrapper" v-bind:class="{'active' : activeSection==2}" v-on:click="setActiveSection(2)">
                                                    <div style="text-align:center; height:50px; margin-top:10px; width:225px; margin-left:auto; margin-right:auto;">
                                                        <fitty  :options="fittyOptionsSm">
                                                            <template v-slot:content>
                                                                <span   v-bind:style="{'font-family':backPage.section2.font}" v-bind:class="{'bold': backPage.section2.bold, 'italic': backPage.section2.italic, 'underline': backPage.section2.underline}">{{backPage.section2.prompt}}</span>
                                                            </template>
                                                        </fitty>
                                                    </div>
                                                    <div style="display:flex; flex:1; margin-bottom:25px;" v-bind:class="{'bullets-bg': backPage.section2.bullets==true}">
                                                        <div class="background lined-bg"  style="display:flex; flex:1">
                                                            <button  class="btn btn-primary btn-edit-section"  v-on:click="setActiveSection(2)"  style="position:relative; margin:auto;">Click to Edit</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- section 3 -->
                                            <div style="display:flex; flex:1; flex-direction:column; border-top:none;" class="edit-section-wrapper" v-bind:class="{'active' : activeSection==3}" v-on:click="setActiveSection(3)">
                                                <div style="text-align:center; height:50px; margin-top:10px; width:450px; margin-left:auto; margin-right:auto;">
                                                        <fitty  :options="fittyOptions">
                                                            <template v-slot:content>
                                                                <span   v-bind:style="{'font-family':backPage.section3.font}" v-bind:class="{'bold': backPage.section3.bold, 'italic': backPage.section3.italic, 'underline': backPage.section3.underline}">{{backPage.section3.prompt}}</span>
                                                            </template>
                                                        </fitty>
                                                    </div>
                                                <div style="display:flex; flex:1; margin-bottom:5px;" v-bind:class="{'bullets-bg': backPage.section3.bullets==true}">
                                                    <div class="background lined-bg"  style="display:flex; flex:1">
                                                        <button  class="btn btn-primary btn-edit-section"  v-on:click="setActiveSection(3)"  style="position:relative; margin:auto;">Click to Edit</button>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <!-- end layout-2x1:1 -->


                                    </div>
                                 </div>
                            </div>
                        </div>

                        <div class="col-sm-12 col edit-col-2" >
                        
                        <div style="display:flex; flex-direction:row">
                           <button class="btn btn-sm btn-secondary" v-on:click="openLayoutSelection()"><b-icon icon="grid1x2-fill"></b-icon> Change Layout</button>
                            <div style="flex-grow:1;"></div>
                            <button class="btn btn-sm btn-secondary" v-on:click="ValidateStep3()" >Continue <b-icon icon="arrow-right"></b-icon> </button>

                        </div>

                            <!-- only for the full page layout "layout-1" -->
                            <div style="margin:20px 0px; border:1px solid #dee2e6; padding:10px; background-color:#fff;" v-if="activeLayout=='layout-1' && showCustom==false">
                                <strong>Keep It Simple</strong>
                                <div><small>You can choose to keep it simple</small></div>
                                <b-button-group >
                                    <b-button variant="secondary" class="btn--small"  v-on:click="setBackground('lined-bg')" v-bind:class="{'active': linedActive}">Lined</b-button>
                                    <b-button variant="secondary" class="btn--small"  v-on:click="setBackground('blank')" v-bind:class="{'active': blankActive}">Blank</b-button>
                                    <b-button variant="secondary" class="btn--small"  v-on:click="setBackground('dotted-bg')" v-bind:class="{'active': dottedActive}">Dotted</b-button>
                                    <b-button variant="secondary" class="btn--small"  v-on:click="setBackground('grid-bg')" v-bind:class="{'active': gridActive}">Grid</b-button>
                                </b-button-group>

                                <div style="margin:5px 0px;"><small>Or</small></div>

                                <button class="btn btn--small btn-secondary" v-on:click="allowCustom()">Click to Edit</button>
                            </div>

                            <!-- edit section 1 -->
                            <div class="customize" v-if="activeSection==1">
                            
                            <div style="margin:20px 0px; border: 1px solid #dee2e6; padding:10px;">
                                <div style="text-align:right;" v-if="activeLayout=='layout-1'">
                                    <button class="btn btn-sm btn--small btn-link btn--link " v-on:click="resetSimple()"><b-icon icon="arrow-left"></b-icon> Keep It Simple</button>
                                </div>
                                <strong>Bullets</strong>
                                <div style="margin-bottom:20px;">
                                    <b-button-group >
                                    <b-button size="sm" class="btn--small btn-secondary"   v-on:click="backPage.section1.bullets =true" v-bind:class="{'active': backPage.section1.bullets==true}">On</b-button>
                                    <b-button size="sm" class="btn--small btn-secondary"  v-on:click="backPage.section1.bullets =false" v-bind:class="{'active': backPage.section1.bullets==false}">Off</b-button>
                                </b-button-group>
                                </div>
                                <strong>Font</strong>
                                <div style="margin-bottom:5px;">
                                    <b-form-select v-on:change="resetFitty()" v-model="backPage.section1.font"  v-bind:style="{fontFamily : backPage.section1.font}" >
                                        <b-form-select-option value="" selected disabled> - Select a Font -</b-form-select-option>
                                        <b-form-select-option  v-for="font in fontOptions" :key="font.value" v-bind:value="font.value"  v-bind:style="{fontFamily : font.value}">{{font.text}}</b-form-select-option>
                                    </b-form-select>
                                </div>
                                <div style="margin-bottom:20px;">
                                    <button style="margin-right:5px" class="btn btn--small btn-secondary " v-bind:class="{'active': backPage.section1.bold}" v-on:click="backPage.section1.bold =!backPage.section1.bold"><b-icon icon="type-bold"></b-icon></button>
                                    <button style="margin-right:5px" class="btn btn--small btn-secondary " v-bind:class="{'active': backPage.section1.italic}" v-on:click="backPage.section1.italic =!backPage.section1.italic"><b-icon icon="type-italic"></b-icon></button>
                                    <button style="margin-right:5px" class="btn btn--small btn-secondary " v-bind:class="{'active': backPage.section1.underline}" v-on:click="backPage.section1.underline =!backPage.section1.underline"><b-icon icon="type-underline"></b-icon></button>
                                </div>
                                
                               <strong>Enter Prompt</strong>
                                <b-form-input v-model="backPage.section1.prompt" placeholder="Create your own prompt" maxlength="35"></b-form-input>
                                
                                <div style="margin:10px 0px; font-weight:bold;">Or select from popular options</div>
                                <div style="text-align:right;">
                                    <b-dropdown variant="-link" size="sm"  class="m-2" right >
                                        <template v-slot:button-content>
                                            <span v-if="!promptFilter">Filter</span>
                                            <span v-if="promptFilter=='Organizational'">Organizational</span>
                                            <span v-if="promptFilter=='CBT-Self-Care'">Self-Care</span>
                                        </template>
                                        <b-dropdown-item-button v-on:click="promptFilter=null">All</b-dropdown-item-button>
                                        <b-dropdown-item-button v-on:click="promptFilter='Organizational'">Organizational</b-dropdown-item-button>
                                        <b-dropdown-item-button v-on:click="promptFilter='CBT-Self-Care'">Self-Care</b-dropdown-item-button>
                                    </b-dropdown>
                                </div>

                                <div style="max-height:300px; overflow-y:scroll;">
                                    <div v-for="item in filteredPromptList" :key="item.value" style="margin:5px 0px;">
                                        <button class="btn btn-secondary btn-block" v-on:click="backPage.section1.prompt=item.value">{{item.value}}</button>
                                    </div>
                                </div>
                            </div>
                            </div>
                            <!-- end section 1 -->


                            <!-- edit section 2 -->
                            <div class="customize" v-if="activeSection==2">
                            <div style="margin:20px 0px; border: 1px solid #dee2e6; padding:10px;">
                                <strong>Bullets</strong>
                                <div style="margin-bottom:20px;">
                                    <b-button-group >
                                        <b-button size="sm" class="btn--small btn-secondary "  v-on:click="backPage.section2.bullets =true" v-bind:class="{'active': backPage.section2.bullets==true}">On</b-button>
                                        <b-button size="sm"  class="btn--small btn-secondary "   v-on:click="backPage.section2.bullets =false" v-bind:class="{'active': backPage.section2.bullets==false}">Off</b-button>
                                 </b-button-group>
                                </div>
                                <strong>Font</strong>
                                <div style="margin-bottom:5px;">
                                    <b-form-select v-on:change="resetFitty()" v-model="backPage.section2.font"  v-bind:style="{fontFamily : backPage.section2.font}" >
                                        <b-form-select-option value="" selected disabled> - Select a Font -</b-form-select-option>
                                        <b-form-select-option  v-for="font in fontOptions" :key="font.value" v-bind:value="font.value"  v-bind:style="{fontFamily : font.value}">{{font.text}}</b-form-select-option>
                                    </b-form-select>
                                </div>
                                <div style="margin-bottom:20px;">
                                    <button style="margin-right:5px" class="btn btn--small btn-secondary " v-bind:class="{'active': backPage.section2.bold}" v-on:click="backPage.section2.bold =!backPage.section2.bold"><b-icon icon="type-bold"></b-icon></button>
                                    <button style="margin-right:5px" class="btn btn--small btn-secondary " v-bind:class="{'active': backPage.section2.italic}" v-on:click="backPage.section2.italic =!backPage.section2.italic"><b-icon icon="type-italic"></b-icon></button>
                                    <button style="margin-right:5px" class="btn btn--small btn-secondary " v-bind:class="{'active': backPage.section2.underline}" v-on:click="backPage.section2.underline =!backPage.section2.underline"><b-icon icon="type-underline"></b-icon></button>
                                </div>
                                
                               <strong>Enter Prompt</strong>
                                <b-form-input v-model="backPage.section2.prompt" placeholder="Create your own prompt" maxlength="35"></b-form-input>
                                
                                <div style="margin:10px 0px; font-weight:bold;">Or select from popular options</div>
                                <div style="text-align:right;">
                                    <b-dropdown variant="-link" size="sm"  class="m-2" right >
                                        <template v-slot:button-content>
                                            <span v-if="!promptFilter">Filter</span>
                                            <span v-if="promptFilter=='Organizational'">Organizational</span>
                                            <span v-if="promptFilter=='CBT-Self-Care'">Self-Care</span>
                                        </template>
                                        <b-dropdown-item-button v-on:click="promptFilter=null">All</b-dropdown-item-button>
                                        <b-dropdown-item-button v-on:click="promptFilter='Organizational'">Organizational</b-dropdown-item-button>
                                        <b-dropdown-item-button v-on:click="promptFilter='CBT-Self-Care'">Self-Care</b-dropdown-item-button>
                                    </b-dropdown>
                                </div>

                                <div style="max-height:300px; overflow-y:scroll;">
                                    <div v-for="item in filteredPromptList" :key="item.value" style="margin:5px 0px;">
                                        <button class="btn btn-secondary btn-block" v-on:click="backPage.section2.prompt=item.value">{{item.value}}</button>
                                    </div>
                                </div>
                            </div>
                            </div>
                            <!-- end section 2 -->
                            

                            <!-- edit section 3 -->
                            <div class="customize" v-if="activeSection==3">
                            <div style="margin:20px 0px; border: 1px solid #dee2e6; padding:10px;">
                                <strong>Bullets</strong>
                                <div style="margin-bottom:20px;">
                                    <b-button-group >
                                    <b-button size="sm" class="btn--small btn-secondary "  v-on:click="backPage.section3.bullets =true" v-bind:class="{'active': backPage.section3.bullets==true}">On</b-button>
                                    <b-button size="sm"  class="btn--small btn-secondary "   v-on:click="backPage.section3.bullets =false" v-bind:class="{'active': backPage.section3.bullets==false}">Off</b-button>
                                </b-button-group>
                                </div>
                                <strong>Font</strong>
                                <div style="margin-bottom:5px;">
                                    <b-form-select v-on:change="resetFitty()" v-model="backPage.section3.font"  v-bind:style="{fontFamily : backPage.section3.font}" >
                                        <b-form-select-option value="" selected disabled> - Select a Font -</b-form-select-option>
                                        <b-form-select-option  v-for="font in fontOptions" :key="font.value" v-bind:value="font.value"  v-bind:style="{fontFamily : font.value}">{{font.text}}</b-form-select-option>
                                    </b-form-select>
                                </div>
                                <div style="margin-bottom:20px;">
                                    <button style="margin-right:5px" class="btn btn--small btn-secondary " v-bind:class="{'active': backPage.section3.bold}" v-on:click="backPage.section3.bold =!backPage.section3.bold"><b-icon icon="type-bold"></b-icon></button>
                                    <button style="margin-right:5px" class="btn btn--small btn-secondary " v-bind:class="{'active': backPage.section3.italic}" v-on:click="backPage.section3.italic =!backPage.section3.italic"><b-icon icon="type-italic"></b-icon></button>
                                    <button style="margin-right:5px" class="btn btn--small btn-secondary " v-bind:class="{'active': backPage.section3.underline}" v-on:click="backPage.section3.underline =!backPage.section3.underline"><b-icon icon="type-underline"></b-icon></button>
                                </div>
                                
                               <strong>Enter Prompt</strong>
                                <b-form-input v-model="backPage.section3.prompt" placeholder="Create your own prompt" maxlength="35"></b-form-input>
                                
                                <div style="margin:10px 0px; font-weight:bold;">Or select from popular options</div>
                                <div style="text-align:right;">
                                    <b-dropdown variant="-link" size="sm"  class="m-2" right >
                                        <template v-slot:button-content>
                                            <span v-if="!promptFilter">Filter</span>
                                            <span v-if="promptFilter=='Organizational'">Organizational</span>
                                            <span v-if="promptFilter=='CBT-Self-Care'">Self-Care</span>
                                        </template>
                                        <b-dropdown-item-button v-on:click="promptFilter=null">All</b-dropdown-item-button>
                                        <b-dropdown-item-button v-on:click="promptFilter='Organizational'">Organizational</b-dropdown-item-button>
                                        <b-dropdown-item-button v-on:click="promptFilter='CBT-Self-Care'">Self-Care</b-dropdown-item-button>
                                    </b-dropdown>
                                </div>

                                <div style="max-height:300px; overflow-y:scroll;">
                                    <div v-for="item in filteredPromptList" :key="item.value" style="margin:5px 0px;">
                                        <button class="btn btn-secondary btn-block" v-on:click="backPage.section3.prompt=item.value">{{item.value}}</button>
                                    </div>
                                </div>
                            </div>
                            </div>
                            <!-- end section 3 -->


                            <!-- edit section 4 -->
                            <div class="customize" v-if="activeSection==4">
                            <div style="margin:20px 0px; border: 1px solid #dee2e6; padding:10px;">
                                <strong>Bullets</strong>
                                <div style="margin-bottom:20px;">
                                    <b-button-group >
                                    <b-button size="sm" class="btn--small btn-secondary "  v-on:click="backPage.section4.bullets =true" v-bind:class="{'active': backPage.section4.bullets==true}">On</b-button>
                                    <b-button size="sm"  class="btn--small btn-secondary "   v-on:click="backPage.section4.bullets =false" v-bind:class="{'active': backPage.section4.bullets==false}">Off</b-button>
                                </b-button-group>
                                </div>
                                <strong>Font</strong>
                                <div style="margin-bottom:5px;">
                                    <b-form-select v-on:change="resetFitty()" v-model="backPage.section4.font"  v-bind:style="{fontFamily : backPage.section4.font}" >
                                        <b-form-select-option value="" selected disabled> - Select a Font -</b-form-select-option>
                                        <b-form-select-option  v-for="font in fontOptions" :key="font.value" v-bind:value="font.value"  v-bind:style="{fontFamily : font.value}">{{font.text}}</b-form-select-option>
                                    </b-form-select>
                                </div>
                                <div style="margin-bottom:20px;">
                                    <button style="margin-right:5px" class="btn btn--small btn-secondary " v-bind:class="{'active': backPage.section4.bold}" v-on:click="backPage.section4.bold =!backPage.section4.bold"><b-icon icon="type-bold"></b-icon></button>
                                    <button style="margin-right:5px" class="btn btn--small btn-secondary " v-bind:class="{'active': backPage.section4.italic}" v-on:click="backPage.section4.italic =!backPage.section4.italic"><b-icon icon="type-italic"></b-icon></button>
                                    <button style="margin-right:5px" class="btn btn--small btn-secondary " v-bind:class="{'active': backPage.section4.underline}" v-on:click="backPage.section4.underline =!backPage.section4.underline"><b-icon icon="type-underline"></b-icon></button>
                                </div>
                                
                               <strong>Enter Prompt</strong>
                                <b-form-input v-model="backPage.section4.prompt" placeholder="Create your own prompt" maxlength="35"></b-form-input>
                                
                                <div style="margin:10px 0px; font-weight:bold;">Or select from popular options</div>
                                <div style="text-align:right;">
                                    <b-dropdown variant="-link" size="sm"  class="m-2" right >
                                        <template v-slot:button-content>
                                            <span v-if="!promptFilter">Filter</span>
                                            <span v-if="promptFilter=='Organizational'">Organizational</span>
                                            <span v-if="promptFilter=='CBT-Self-Care'">Self-Care</span>
                                        </template>
                                        <b-dropdown-item-button v-on:click="promptFilter=null">All</b-dropdown-item-button>
                                        <b-dropdown-item-button v-on:click="promptFilter='Organizational'">Organizational</b-dropdown-item-button>
                                        <b-dropdown-item-button v-on:click="promptFilter='CBT-Self-Care'">Self-Care</b-dropdown-item-button>
                                    </b-dropdown>
                                </div>

                                <div style="max-height:300px; overflow-y:scroll;">
                                    <div v-for="item in filteredPromptList" :key="item.value" style="margin:5px 0px;">
                                        <button class="btn btn-secondary btn-block" v-on:click="backPage.section4.prompt=item.value">{{item.value}}</button>
                                    </div>
                                </div>
                            </div>
                            </div>
                            <!-- end section 4 -->

                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

.bold{
    font-weight:bold;
}
.italic{
    font-style: italic;
}
.underline{
    text-decoration: underline;
}


.lined-bg{
    background-image: 
    linear-gradient(0deg, transparent 5em, rgba(212, 212, 212, 0.2) 0, transparent 5.1em), 
    linear-gradient(rgba(162,162,162,.3) 1px, transparent 0);
    background-size: 100% 1.5em, 100% 27px;
    background-repeat: repeat, repeat;
    margin-top:30px;

}

.bullets-bg{
    background-image: 
    url("../assets/checkbox.png");
    background-size:   21.45px;
    background-repeat:   repeat-y;
    background-position:  20px -8px;
    padding-left:50px;
    padding-right:20px;
}

.dotted-bg{
    background-image: radial-gradient(rgb(170, 170, 170) 1px, transparent 0);
    background-size: 20px 20px;
    background-position: -9px -9px;
}

.grid-bg{
    background-size: 21.2px 21.2px;
    background-image:
    linear-gradient(to right, rgba(170, 170, 170, .4) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(170, 170, 170, .4) 1px, transparent 1px);
    border: 1px solid #c0c0c070;
}

.btn-edit-section{
    /* opacity:0;
    transition:all .35s !important; */
    display:none;
}
.edit-section-wrapper{
    transition:all .3s;
    border:1px solid #333;
}

.edit-section-wrapper-no-border{
    transition:all .3s;
}

/* .edit-section-wrapper:nth-child(1n+2) {
  border-top:none;
  } */

.edit-section-wrapper.active{
    box-shadow: 0px 0px 40px 40px rgba(0,0,0,.2);
}
.edit-section-wrapper:hover{
    background-color: rgba(204, 236, 252, 0.2);
    
}

.edit-section-wrapper:hover .btn-edit-section{
    opacity:1;
    
}

#back-wrapper{
    background-color:#83c9dc;
    padding-top:20px;
}

.edit-col-2{
    max-width:350px;
}

@media (max-width: 750px) {
  #back-wrapper{
    max-height:550px;
    margin-bottom:10px;
    }

    .edit-col-2{
        max-width:unset;
    }
}

#back-page{
    transform-origin: top center;
    position: relative;
    padding: 10px;
    border: 1px solid #c0c0c0;
    box-shadow: 0px 0px 10px 10px rgba(0,0,0,.05);
    right: -50%;
    transition: all .5s;
    height:850px;
    width:550px;
    border-radius:10px;
    background-color:#fff;
    display:flex; flex-direction:column;
}

#back-page.metal::before{
    content:url("../assets/binding-right.png");
    position: absolute;
    right: -25px;
    top: 15px;
    z-index: 999;
}
#back-page.spiral::before{
    content:url("../assets/binding-sp-right.png");
    position: absolute;
    right: -25px;
    top: 15px;
    z-index: 999;
}



div.layout-title-sm{
    font-size:12px;
    color:#c0c0c0;
}

.layout-card{
    min-height:325px;
    margin-bottom:20px;
    cursor: pointer;
}

.layout-card:hover{
    box-shadow: 0px 0px 10px #c0c0c0;
}

.layout-card.selected{
    box-shadow: 0 0 10px 10px #658a94;
    border: 1px solid #99d9ea;
}

.customize{
    background-color:#fff;
}

</style>

<script>
import _ from 'lodash';
var VueScrollTo = require('vue-scrollto');


export default {
    props: ['journalProperties'],
    data: function () {
        return {
            fittyOptions: {
                minSize: 12,
                maxSize: 35,
                multiLine:false,
                observeMutations:
                {
                    subtree: true,
                    childList: true,
                    characterData: true
                }
                
            },
            fittyOptionsSm: {
                minSize: 12,
                maxSize: 20,
                multiLine:true,
                observeMutations:
                {
                    subtree: true,
                    childList: true,
                    characterData: true
                }
                
            },
            fontOptions:[
                {value:"Amatic SC", text: 'Amatic SC'},
                {value:"Barriecito", text: 'Barriecito'},
                {value:"Great Vibes", text: 'Great Vibes'},
                {value:"Indie Flower", text: 'Indie Flower'},
                {value:"Londrina Shadow", text: 'Londrina Shadow'},
                {value:"Megrim", text: 'Megrim'},
                {value:"Mystery Quest", text: 'Mystery Quest'},
                {value:"Nothing You Could Do", text: 'Nothing You Could Do'},
                {value:"Pacifico", text: 'Pacifico'},
                {value:"Parisienne", text: 'Parisienne'},
                {value:"Permanent Marker", text: 'Permanent Marker'},
                {value:"Quicksand", text: 'Quicksand'},
                {value:"Rock Salt", text: 'Rock Salt'},
                {value:"Source Sans Pro", text: 'Source Sans Pro'},
                {value:"The Girl Next Door", text: 'The Girl Next Door'},
            ],
            promptList:[
                {category: "Organizational", value: "Tomorrow's To-Do's"},
                {category: "Organizational", value:"Today's To-Do's"},
                {category: "Organizational", value:"Daily Goals"},
                {category: "Organizational", value:"My Tasks Today"},
                {category: "Organizational", value:"Daily Reminders"},
                {category: "Organizational", value: "Daily Food Log"},
                {category: "Organizational", value:"Daily Financial Goals"},
                {category: "Organizational", value:"Daily Exercise Log"},
                {category: "Organizational", value:"Work Tasks For The Day"},
                {category: "Organizational", value:"Notes"},
                {category: "CBT-Self-Care", value:"Today's Positives"},
                {category: "CBT-Self-Care", value:"Tomorrow's Self-Care"},
                {category: "CBT-Self-Care", value:"Today's Self-Care"},
                {category: "CBT-Self-Care", value:"Tomorrow's Healthy Habits"},
                {category: "CBT-Self-Care", value:"Today's Healthy Habits"},
                {category: "CBT-Self-Care", value:"Attitude of Gratitude"},
                {category: "CBT-Self-Care", value:"Today I Feel"},
                {category: "CBT-Self-Care", value:"I Am"},
                {category: "CBT-Self-Care", value:"Tomorrow's Challenges"},
                {category: "CBT-Self-Care", value:"Today's Challenges"},
                {category: "CBT-Self-Care", value:"Things I Learned Today"},
                {category: "CBT-Self-Care", value:"Today I Say 'Yes' To"},
                {category: "CBT-Self-Care", value:"Today I Say 'No' To"},
                {category: "CBT-Self-Care", value:"Today I Am Inspired By"},
                {category: "CBT-Self-Care", value:"My Thought Log"},
                {category: "CBT-Self-Care", value:"My Favorite Way To Spend The Day Is"},
                {category: "CBT-Self-Care", value:"Things That Made Me Smile Today"},
                {category: "CBT-Self-Care", value:"If My Body Could Talk It Would Say"},
                {category: "CBT-Self-Care", value:"Today's 'Life Is Great' Moments"},
                {category: "CBT-Self-Care", value:"Words I Need To Hear Today"},
                {category: "CBT-Self-Care", value:"Things That Bothered Me Today"},
                {category: "CBT-Self-Care", value:"Things My Partner Did Today That Made Me Happy"},
                {category: "CBT-Self-Care", value:"Things My Partner Did Today That Made Me Upset"},
                {category: "CBT-Self-Care", value:"What's On My Mind"},
                {category: "CBT-Self-Care", value:"Today's Self Love"}
            ],
            binding:null,
            promptFilter:null,
            activeLayout:null,
            activeSection:null,
            showLayoutSelect: true,
            showCustom:false,
            linedActive:false,
            gridActive:false,
            dottedActive: false,
            blankActive: false,
            backPage:{
                layout:null,
                simpleBackground:null,
                section1:{
                    prompt: "Click To Add Prompt",
                    font:"",
                    background:null,
                    bullets:false,
                    bold:false,
                    italic:false,
                    underline:false,
                    isSimple:false,
                },
                section2:{
                    prompt: "Click To Add Prompt",
                    font:"",
                    background:null,
                    bullets:false,
                    bold:false,
                    italic:false,
                    underline:false
                },
                section3:{
                    prompt: "Click To Add Prompt",
                    font:"",
                    background:null,
                    bullets:false,
                    bold:false,
                    italic:false,
                    underline:false
                },
                section4:{
                    prompt: "Click To Add Prompt",
                    font:"",
                    background:null,
                    bullets:false,
                    bold:false,
                    italic:false,
                    underline:false
                }
            }
            
        }
    },
    methods:{
         ValidateStep3: function(){
              var isValid = true;
            //make sure that all sections have a prompt?
            
            var cleanLayout = this.activeLayout.replace("layout-", "");
            var sections = cleanLayout.split(":");
            var sectionCount = 0;

            sections.forEach(element => sectionCount = sectionCount + parseInt(element));
                        

            switch (sectionCount) {
                case 1:
                    if (this.showCustom != false){
                        // keep it simple
                        if( this.backPage.section1.prompt =="" || this.backPage.section1.prompt == null || this.backPage.section1.prompt =="Click To Add Prompt" ){
                            isValid = false;
                        }
                       
                    }
                     break;
                case 2:
                    if( this.backPage.section1.prompt =="" || this.backPage.section1.prompt ==null || this.backPage.section1.prompt =="Click To Add Prompt" ){
                        isValid = false;
                    }
                    if( this.backPage.section2.prompt =="" ||  this.backPage.section2.prompt ==null ||  this.backPage.section2.prompt =="Click To Add Prompt" ){
                        isValid = false;
                    }
                    break;
                case 3:
                    if( this.backPage.section1.prompt =="" ||  this.backPage.section1.prompt ==null ||  this.backPage.section1.prompt =="Click To Add Prompt" ){
                        isValid = false;
                    }
                    if( this.backPage.section2.prompt =="" || this.backPage.section2.prompt ==null || this.backPage.section2.prompt =="Click To Add Prompt" ){
                        isValid = false;
                    }
                    if( this.backPage.section3.prompt =="" || this.backPage.section3.prompt ==null || this.backPage.section3.prompt =="Click To Add Prompt" ){
                        isValid = false;
                    }
                    break;
                case 4:
                    if( this.backPage.section1.prompt =="" || this.backPage.section1.prompt ==null || this.backPage.section1.prompt =="Click To Add Prompt" ){
                        isValid = false;
                    }
                    if( this.backPage.section2.prompt =="" || this.backPage.section2.prompt ==null || this.backPage.section2.prompt =="Click To Add Prompt" ){
                        isValid = false;
                    }
                    if( this.backPage.section3.prompt =="" || this.backPage.section3.prompt ==null || this.backPage.section3.prompt =="Click To Add Prompt" ){
                        isValid = false;
                    }
                    if( this.backPage.section4.prompt =="" || this.backPage.section4.prompt ==null || this.backPage.section4.prompt =="Click To Add Prompt" ){
                        isValid = false;
                    }
                    break;
                
                default:
                    isValid = false;
            }
            if (isValid == true){
                
                this.backPage.layout = this.activeLayout;
                this.backPage.simpleBackground = null;

                 if (this.gridActive == true){
                    this.backPage.simpleBackground = "grid";
                }
                if (this.blankActive == true){
                    this.backPage.simpleBackground = "blank";
                }
                if (this.dottedActive == true){
                    this.backPage.simpleBackground = "dotted";
                }
                if (this.linedActive == true){
                    this.backPage.simpleBackground = "lined";
                }

                this.$emit('updateStep3', this.backPage)
            }else{
                this.$bvModal.show("modal-error-back");
                //console.log('Make sure all sections have a prompt!');
            }
            
        },
        blankPage(){
            this.activeLayout ='layout-1'
            this.backPage.section1.prompt = null;
            this.showCustom = false;
            this.showLayoutSelect = false;
            this.blankActive = true;
            this.backPage.section1.isSimple = true;
            this.scaleBackPage();

            this.ValidateStep3();
        },
        duplicateFront(){
            var props = JSON.parse(JSON.stringify(this.journalProperties));
            this.backPage = props.frontPage;
            this.activeLayout = props.frontPage.layout;
            this.showCustom = true;
            //check for simple options
             switch (props.frontPage.simpleBackground) {
                case 'grid':
                    this.setBackground("grid-bg");
                    break;
                case "blank":
                    this.setBackground("blank");
                    break;
                case "dotted":
                    this.setBackground("dotted-bg");
                    break;
                case "lined":
                    this.setBackground("lined-bg");
                    break;
             }
            
            this.showLayoutSelect = false;
            this.scaleBackPage();

        },
        resetFitty(){
            //reset by updating the the text
            var sec1Prompt = this.backPage.section1.prompt
            var sec2Prompt = this.backPage.section2.prompt
            var sec3Prompt = this.backPage.section3.prompt
            var sec4Prompt = this.backPage.section4.prompt

            this.backPage.section1.prompt = "";
            this.backPage.section2.prompt = "";
            this.backPage.section3.prompt = "";
            this.backPage.section4.prompt = "";
            setTimeout(()=>{ 
                this.backPage.section1.prompt = sec1Prompt;
                this.backPage.section2.prompt = sec2Prompt;
                this.backPage.section3.prompt = sec3Prompt;
                this.backPage.section4.prompt = sec4Prompt;
             }, 50);

        },
        setActiveSection: function(v){
            this.activeSection = v;
            VueScrollTo.scrollTo(".edit-col-2", 500);
        },
        setLayout: function(v){
            VueScrollTo.scrollTo(".card-header", 500);
            this.activeLayout = v;
            this.showLayoutSelect = false;
            this.scaleBackPage();
        },
        scaleBackPage: _.debounce(function(){
        
        //set binding also
        var variant = document.getElementById('ProductSelect-product-template').value;
        var productJson =  JSON.parse(document.getElementById('ProductJson-product-template').innerHTML);

        //console.log(variant);
        //console.log(productJson);

        var valObj = productJson.variants.filter(function(elem){
            if(elem.id == variant) return elem;
        });
        console.log(valObj);

        if (valObj[0].title=="Metal Wire-O"){
            this.binding = "metal";
        }else{
            this.binding = "spiral";
        }


        var imgWidth = 550;
        var imgHeight = 850;
        
         var $wrapper = document.getElementById("back-wrapper");
         var $el = document.getElementById("back-page");

         var w = $wrapper.clientWidth -50;
         var h =window.innerHeight - 50;

         var scaledImg = $el.getBoundingClientRect();
         var scaledH = scaledImg.height;
         var scaledW = scaledImg.width;

          //console.log("back Scaled Width: " + scaledW + ", " + "Height: " + scaledH);
         // console.log("back Wrapper Width: " + w + ", " + "Height: " + h);
            var scale;
            var useDimension ="w";

            if (scaledW > 0 && scaledH > 0){
                if(w < h){
                    //check to make sure the height isn't too big
                    if( scaledH < h){
                        //use width
                        useDimension ="w";
                    }else{
                        //use height
                        useDimension ="h";
                    }
                    
                }else{
                    //height is smaller but check width
                    if(scaledW <= w){
                    //use height 
                        useDimension ="h";
                    }else{
                        //use width
                        useDimension ="w";
                    }
                
                }

                if (useDimension == "w"){
                    scale = (w / imgWidth);
                    //if (scale <0){scale=0.3}
                    this.changeScale(scale);
                }else{
                    scale = (h / imgHeight);
                // if (scale <0){scale=0.3}
                    this.changeScale(scale);
                }
            }

        }, 100),
        changeScale(newScale){
            //console.log(newScale);
            var div =  document.getElementById("back-page");
            div.setAttribute("style", "transform: translate(-50%) scale(" + newScale + ")");
        },
        setBackground: function(style){
            this.showCustom = false;
            this.linedActive = false;
            this.blankActive = false;
            this.dottedActive = false;
            this.gridActive = false;
            this.backPage.section1.prompt = null;
            this.backPage.section1.isSimple = true;

            if (style == "lined-bg"){
                this.linedActive = true;
                
            }
            if (style == "blank"){
                this.blankActive = true;
               
            }
            if (style == "dotted-bg"){
                this.dottedActive = true;
                
            }
            if (style == "grid-bg"){
                this.gridActive = true;
            }
        },
        allowCustom: function(){
            this.linedActive = false;
            this.blankActive = false;
            this.dottedActive = false;
            this.gridActive = false;
            this.showCustom = true;
            this.backPage.section1.background = "lined-bg";
            this.activeSection = 1;
            this.backPage.section1.isSimple = false;

            VueScrollTo.scrollTo(".edit-col-2", 500);

        },
        openLayoutSelection: function(){
            this.showLayoutSelect=true;
            this.showCustom = false;
            this.backPage.section1.background = "blank"
            this.backPage.section1.prompt =  "Click To Add Prompt";
            this.backPage.section1.bullets = false;
            this.activeSection = null;
        },
        clearEdit: function(e){
            //this.activeSection = null;
            if(e.srcElement.id == 'back-wrapper'){
                this.activeSection = null;
            }
            //console.log(e.srcElement.id);
        },
        resetSimple: function(){
            this.activeSection = null;
            this.showCustom = false;
            this.backPage.section1.background = null;
            this.backPage.section1.bullets = false;
            this.setBackground("lined-bg");
        }
    },
    created() {
        window.addEventListener("resize", this.scaleBackPage);
    },
    destroyed() {
        window.removeEventListener("resize", this.scaleBackPage);
    },
    computed:{
        filteredPromptList: function(){
            if (this.promptFilter == 'Organizational'){
                return this.promptList.filter(item=>{
                    return item.category == "Organizational";
                });
            }else if(this.promptFilter == 'CBT-Self-Care') {
                return this.promptList.filter(item=>{
                    return item.category == "CBT-Self-Care";
                });
            }else{
                 return this.promptList;
            }
        }
    }
}
</script>