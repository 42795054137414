<template>
  <div id="app" style="width:100%;">
    <Designer/>
  </div>
</template>

<script>

import Designer from './components/Designer'

export default {
  name: 'App',
  components: {
    Designer
  }
}
</script>

<style lang="scss" scoped>
   @import './src/assets/site.scss';
</style>

<style>
@import url('https://fonts.googleapis.com/css2?family=Amatic+SC&family=Barriecito&family=Great+Vibes&family=Indie+Flower&family=Londrina+Shadow&family=Megrim&family=Mystery+Quest&family=Nothing+You+Could+Do&family=Pacifico&family=Parisienne&family=Permanent+Marker&family=Quicksand&family=Rock+Salt&family=Source+Sans+Pro&family=The+Girl+Next+Door&display=swap');
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

div.layout-divider{
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.4);
}

div.layout-divider-right{

  border-right: 1px solid rgba(0, 0, 0, 0.4);
}

.card-header{
    background-color:rgb(112 ,191 ,212);
}

.designer > .designer-tabs > .card-header{
  background-color:#fff;
  border:5px solid #99d9ea;
}


.nav-pills .nav-link {
    color: #99d9ea;
    background-color: transparent;
    border: 2px solid #99d9ea;
    margin-right:10px;
}

.nav-pills .nav-link.disabled {
    color: #333;
    background-color: rgb(233, 233, 233) !important;
    border: 2px solid rgb(233, 233, 233) !important;
    cursor:not-allowed !important;
}


.nav-pills .nav-link.active {
    color: #fff;
    background-color: #99d9ea !important;
    border: 2px solid #99d9ea !important;
}


.btn-secondary.active{
  background-color: #83c9dc !important;
  border-color: #83c9dc !important;
}

.btn-block{
    width:100% !important;
}

</style>


